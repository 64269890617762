import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import "./style.css";
import { ApiPostCall } from "../Functions/connector";
import FooterLinks from "./footerlinks";
import Loader from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "universal-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useLocation } from "react-router-dom";

const Login = (props) => {
  const location = useLocation()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoader, setIsLoader] = useState(false);
  const [rentalCode, setRentalCode] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPwd, setInvalidPwd] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const rentalNumber = params.get("rc");

    if (rentalNumber !== null) {
      setRentalCode(rentalNumber);
    }
  }, [location?.search]);

  const userLogin = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setInvalidEmail(true);
      return;
    } else if (password.trim() === "") {
      setInvalidEmail(false);
      setInvalidPwd(true);
      return;
    } else {
      setInvalidEmail(false);
      setInvalidPwd(false);
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("UserEmail", email);
    urlencoded.append("Password", password);

    setIsLoader(true);
    setIsError(false);

    try {
      const result = await ApiPostCall("/Auth/Login", urlencoded);
      // console.log("got it", result);

      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs);

        if (responseRs.hasOwnProperty("Error")) {
          setIsError(true);
        } else {
          const cookies = new Cookies();
          cookies.set("IsPersist", "yes", {
            maxAge: 3000,
          });

          window.localStorage.setItem("userData", responseRs);
          window.localStorage.setItem("user", "Normal User");
          window.localStorage.setItem("isWrongDialedOrBundle", false);

          const encodedString = base64_encode(`${email}:${password}`);
          window.localStorage.setItem("Token", encodedString);

          if (rentalCode == 0 || rentalCode === "" || rentalCode === null) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/rental?rc=" + rentalCode + "&er=1";
          }
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setIsLoader(false);
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePwd = (e) => {
    setPassword(e.target.value);
  };

  const hidePassword = (e) => {
    setIsShowPassword(false);
    setPasswordType("password");
  };

  const showPassword = (e) => {
    setIsShowPassword(true);
    setPasswordType("text");
  };

  return (
    <div className="row m-0 loginPageBody">
      <div className="card col-lg-4 m-auto border-0 ">
        <div className="row  p-1">
          <div className="col-12 d-flex justify-content-center align-self-center">
            <img src="/Tns-Logo.png" alt="Tns Logo" />
          </div>
          <form onSubmit={userLogin}>
            <div className="col-12 ">
              <div className="card mt-4 border-0 authCard p-4">
                <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                  Login
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="input-2">Email Address</label>
                  <input
                    type="text"
                    value={email}
                    onChange={handleChangeEmail}
                    className="form-control"
                    id="input-2"
                    placeholder="Enter Email"
                  />
                  {invalidEmail && (
                    <span style={{ color: "red" }}>Required*</span>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="input-4">Password</label>
                  <input
                    type={passwordType}
                    value={password}
                    onChange={handleChangePwd}
                    className="form-control"
                    id="input-4"
                    placeholder="Enter Password"
                  />
                  {!isShowPassword && (
                    <span
                      className="passwordShow"
                      onClick={showPassword}
                    >
                      <FaEye className="inputIcon" role="button" />
                    </span>
                  )}
                  {isShowPassword && (
                    <span
                      className="passwordHide"
                      onClick={hidePassword}
                    >
                      <FaEyeSlash className="inputIcon" role="button" />
                    </span>
                  )}
                  {invalidPwd && (
                    <span style={{ color: "red" }}>Required*</span>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                >
                  Login
                  {isLoader && (
                    <span className="loaderCustom">
                      <Loader
                        type="Oval"
                        color="#142454"
                        height={25}
                        width={25}
                        timeout={100000}
                      />
                    </span>
                  )}
                </button>
                {isError && (
                  <span style={{ color: "red" }}>
                    Username or password is incorrect!
                  </span>
                )}
                <a
                  href="/forgotpassword"
                  className="text-center themeTxtColor mt-4"
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>

      <FooterLinks
        content={"Don't have an account?"}
        link={"/register"}
        linktext={"Sign Up Now!"}
      />
    </div>
  );
};

export default Login;

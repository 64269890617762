import { ApiGetCall } from "../Functions/connector";
import $ from "jquery";
import { getSmallestDate } from "../Helper/HelperFun";

export const ValidateBundleData = async (
  data,
  bundleSubLinkData,
  Equipment,
  DeliveryShippingData,
  BeginEndDate
) => {
  let {
    minimumPeriod,
    bundles,
    maximumPeriod,
    insurance,
    optAllowMultipleRentals,
    Counter,
    ClientCode,
    optRequestProgramName,
    AdminComment,
  } = bundleSubLinkData[0];
  let { kntCountries, smss } = bundles[0];
  var error_message = "";
  var isValidateDate = false;

  //ValidateBundle
  if (data?.signUpRepInfo?.value == "0")
    return { isDateVisible: true, err_date_msg: "Please select a agent name" };

  //ValidateBundle
  if (data?.Bundledata?.value == "-1")
    return { isDateVisible: true, err_date_msg: "Please select a Bundle" };

  //ValidateEquipment
  let alreadyHaveSimError = "";
  if (Equipment?.MultipleEquipments) {
    if (
      data?.EquipmentBaseCode?.value == "-1" ||
      data?.EquipmentBaseCode?.value == null ||
      Equipment?.EquipmentCode == ""
    )
      return {
        isDateVisible: true,
        err_date_msg: "Please select an Equipment",
      };
    else if (data?.EquipmentBaseCode?.value == "9999") {
      for (let i = 0; i < Equipment?.MultipleEquipmentsData?.length; i++) {
        if (
          Equipment?.MultipleEquipmentsData[i]?.AlreadyHaveSim == "" ||
          Equipment?.MultipleEquipmentsData[i]?.AlreadyHaveSim?.replace(
            /\s/g,
            ""
          ).length != 14
        ) {
          alreadyHaveSimError += `Please enter Your 19 Digit SIM card ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""
            }`;
        } else if (Equipment?.MultipleEquipmentsData[i]?.error != "") {
          alreadyHaveSimError += `${Equipment?.MultipleEquipmentsData[i]?.error
            } ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""}`;
        }
      }
    } else if (kntCountries?.length > 1) {
      if (data?.kntCountries?.value == "0" || Equipment?.kntCountryCode == "")
        return {
          isDateVisible: true,
          err_date_msg: "Please select stay local number",
        };
    } else if (smss?.length > 1) {
      if (data?.smss?.value == "-1" || Equipment?.smssCode == "")
        return { isDateVisible: true, err_date_msg: "Please select sms" };
    }
  } else {
    for (let i = 0; i < Equipment?.MultipleEquipmentsData?.length; i++) {
      if (
        Equipment?.MultipleEquipmentsData[i]?.EquipmentBaseCode == "" ||
        Equipment?.MultipleEquipmentsData[i]?.EquipmentBaseCode == null
      ) {
        alreadyHaveSimError += `Please select an Equipment for order #${i + 1},
        `;
      }
      if (Equipment?.MultipleEquipmentsData[i]?.EquipmentBaseCode == "9999") {
        if (
          Equipment?.MultipleEquipmentsData[i]?.AlreadyHaveSim == "" ||
          Equipment?.MultipleEquipmentsData[i]?.AlreadyHaveSim?.replace(
            /\s/g,
            ""
          ).length != 14
        ) {
          alreadyHaveSimError += `Please enter Your 19 Digit SIM card ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""
            }`;
        } else if (Equipment?.MultipleEquipmentsData[i]?.error != "") {
          alreadyHaveSimError += `${Equipment?.MultipleEquipmentsData[i]?.error
            } ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""}`;
        }
      }
      if (kntCountries?.length > 1) {
        if (
          Equipment?.MultipleEquipmentsData[i]?.kntCountries == "" ||
          Equipment?.MultipleEquipmentsData[i]?.kntCountries == null
        )
          alreadyHaveSimError += `Please select stay local number ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""
            }`;
      }
      if (smss?.length > 1) {
        if (
          Equipment?.MultipleEquipmentsData[i]?.smss == "" ||
          Equipment?.MultipleEquipmentsData[i]?.smss == null
        )
          alreadyHaveSimError += `Please select sms ${Equipment?.Quantity > 1 ? `for order #${i + 1}, ` : ""
            }`;
      }
    }
  }
  if (alreadyHaveSimError != "") {
    return {
      isDateVisible: true,
      err_date_msg: alreadyHaveSimError,
    };
  }
  //ValidateInsurance
  // if (insurance == "optional") {
  //   if (
  //     !document.getElementById("InsuranceRadioYes").checked &&
  //     !document.getElementById("InsuranceRadioNo").checked
  //   )
  //     return { isDateVisible: true, err_date_msg: "Please select Insurance" };
  //   else if (
  //     document.getElementById("InsuranceRadioNo").checked &&
  //     !data?.NoInsuranceConfirmationCB.checked
  //   )
  //     return {
  //       isDateVisible: true,
  //       err_date_msg:
  //         "You have opted to decline insurance. Please make sure to confirm the implications of this choice in the relevant checkbox.",
  //     };
  // }

  //ValidatekntCountries
  // if (kntCountries?.length > 0) {
  //   if (data?.kntCountries.value == "0")
  //     return {
  //       isDateVisible: true,
  //       err_date_msg: "Please select stay local number",
  //     };
  //   else if (data?.kntCountries.value != "-1") {
  //   }
  // }

  // if (smss?.length > 1) {
  //   if (data?.smss.value == "-1")
  //     return { isDateVisible: true, err_date_msg: "Please select sms" };
  // }

  //ValidateDates
  let BeginDate_Error = "";
  let EndDate_Error = "";
  let Date_Error = "";
  if (BeginEndDate?.MultipleDatesData.length > 0) {
    for (let i = 0; i < BeginEndDate?.MultipleDatesData?.length; i++) {
      if (BeginEndDate?.MultipleDatesData[i]?.begin_Date == "") {
        BeginDate_Error += `Please enter begin date ${Equipment?.Quantity > 1 && !BeginEndDate?.MultipleDates
            ? `for order #${i + 1}, `
            : ""
          }`;
      } else if (
        BeginEndDate?.isEndDateVisible &&
        BeginEndDate?.MultipleDatesData[i]?.end_Date == ""
      ) {
        EndDate_Error += `Please enter begin date ${Equipment?.Quantity > 1 && !BeginEndDate?.MultipleDates
            ? `for order #${i + 1}, `
            : ""
          }`;
      } else if (BeginEndDate?.MultipleDatesData[i]?.error != "") {
        Date_Error += `${BeginEndDate?.MultipleDatesData[i]?.error} ${Equipment?.Quantity > 1 && !BeginEndDate?.MultipleDates
            ? `for order #${i + 1}, `
            : ""
          }`;
      }
    }
  } else {
    BeginDate_Error += `Please enter begin date and end date`;
  }
  if (BeginDate_Error != "") {
    return {
      isDateVisible: true,
      err_date_msg: BeginDate_Error,
    };
  } else if (EndDate_Error != "") {
    return {
      isDateVisible: true,
      err_date_msg: EndDate_Error,
    };
  } else if (Date_Error != "") {
    return {
      isDateVisible: true,
      err_date_msg: Date_Error,
    };
  }
  // let begin_date = new Date(data?.startDate.value);

  // if (maximumPeriod == null) {
  //   if (begin_date == "Invalid Date")
  //     return { isDateVisible: true, err_date_msg: "Please Start Date" };
  // } else {
  //   let end_date = new Date(data?.endDate?.value);

  //   if (begin_date == "Invalid Date" || end_date == "Invalid Date")
  //     return { isDateVisible: true, err_date_msg: "Please select Dates" };

  //   let diffTime = Math.abs(end_date - begin_date);
  //   let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   days = days + 1;
  //   let month = days / 30;
  //   let minDaysOrMonth = minimumPeriod.split("|");
  //   let maxDaysOrMonth = maximumPeriod.split("|");

  //   minDaysOrMonth[0] = parseInt(minDaysOrMonth[0]);
  //   maxDaysOrMonth[0] = parseInt(maxDaysOrMonth[0]);

  //   if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "d") {
  //     if (days < minDaysOrMonth[0]) {
  //       error_message =
  //         "The minimum rental period is " + minDaysOrMonth[0] + " days.";
  //       isValidateDate = true;
  //     } else if (days > maxDaysOrMonth[0]) {
  //       error_message =
  //         "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
  //       isValidateDate = true;
  //     } else {
  //       // $(parent).find(".error_day").text("");
  //     }
  //   } else if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "m") {
  //     if (days < minDaysOrMonth[0]) {
  //       error_message =
  //         "The minimum rental period is " + minDaysOrMonth[0] + " days.";
  //       isValidateDate = true;
  //     } else if (month > maxDaysOrMonth[0]) {
  //       error_message =
  //         "The maximum rental period for this group is " +
  //         maxDaysOrMonth[0] +
  //         " month.";
  //       isValidateDate = true;
  //     } else {
  //       // $(parent).find(".error_day").text("");
  //     }
  //   } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "m") {
  //     if (month < minDaysOrMonth[0]) {
  //       error_message =
  //         "The minimum rental period is " + maxDaysOrMonth[0] + " month.";
  //       isValidateDate = true;
  //     } else if (month > maxDaysOrMonth[0]) {
  //       error_message =
  //         "The maximum rental period is " + maxDaysOrMonth[0] + " month.";
  //       isValidateDate = true;
  //     } else {
  //       // $("#next1").attr("disabled", false);
  //       // $(parent).find(".error_day").text("");
  //     }
  //   } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "d") {
  //     if (month < minDaysOrMonth[0]) {
  //       error_message =
  //         "The minimum rental period is " + minDaysOrMonth[0] + " month.";
  //       isValidateDate = true;
  //     } else if (days > maxDaysOrMonth[0]) {
  //       error_message =
  //         "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
  //       isValidateDate = true;
  //     } else {
  //       // $("#next1").attr("disabled", false);
  //       // $(".error_day").text("");
  //     }
  //   } else {
  //     if (days < minDaysOrMonth[0]) {
  //       error_message =
  //         "The minimum rental period is " + minDaysOrMonth[0] + " days.";
  //       isValidateDate = true;
  //     }
  //   }
  //   if (isValidateDate)
  //     return { isDateVisible: isValidateDate, err_date_msg: error_message };
  // }

  //ValidateUserDetails
  if (data?.FirstName.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter First Name" };

  if (data?.lastName.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter Last Name" };

  if (
    !data?.email.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return { isDateVisible: true, err_date_msg: "Please enter email address" };

  if (optRequestProgramName) {
    if (data?.nameOfIsraelProgram.value == "")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter name of Israel program",
      };
  }
  if (data?.phoneNumber.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter phone number" };

  if (data?.streetAddress.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter street address" };

  if (data?.city.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter city" };

  //ValidateCountry;
  let CountryCode = data?.CountryData?.value;
  if (CountryCode == "-1")
    return { isDateVisible: true, err_date_msg: "Please select the country!" };
  else {
    if (CountryCode == "1" || CountryCode == "7") {
      if (data?.StateData?.value == "-1")
        return {
          isDateVisible: true,
          err_date_msg: "Please enter the State/Province!",
        };
    }
  }

  if (CountryCode != "4") {
    if (data?.ZipCode.value == "" || data?.ZipCode.value == "0")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter the zip!",
      };
  }

  //BHLT
  if (AdminComment == "BHLT") {
    if (data?.TalmidName.value == "")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter Talmid's Name",
      };
    if (data?.FatherName.value == "")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter Father's Name",
      };
    if (data?.YeshivaName.value == "")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter Yeshiva's Name",
      };
    if (data?.RabbiName.value == "")
      return { isDateVisible: true, err_date_msg: "Please enter Rabbi's Name" };
  }

  //shipping;
  if (Equipment.ShowShipping) {
    error_message = "";

    if (data?.shipping.value == "-2") {
      error_message = "Please select shipping";
      isValidateDate = true;
    }
    // else if (
    //   DeliveryShippingData?.ShippingMethod == "UseBillingAddress" &&
    //   DeliveryShippingData?.isVisible
    // ) {
    else if (
      CountryCode !=
      data?.shipping.options[data?.shipping.selectedIndex].getAttribute(
        "countrie-id"
      ) &&
      DeliveryShippingData?.isVisible
    ) {
      error_message =
        "We're sorry, but the delivery method you chose is not available for the address you entered. Please check that you're providing the correct delivery address.";
      isValidateDate = true;
    } else if (
      DeliveryShippingData?.ShippingMethod == "DifferentAddress" &&
      DeliveryShippingData?.isVisible
    ) {
      if (data?.ShippingName.value == "") {
        error_message = "Please select shipping name";
        isValidateDate = true;
      } else if (data?.ShippingPhoneNumber.value == "") {
        error_message = "Please select shipping phone number";
        isValidateDate = true;
      } else if (data?.ShippingStreetAddress.value == "") {
        error_message = "Please select shipping street address";
        isValidateDate = true;
      } else if (data?.ShippingCity.value == "") {
        error_message = "Please select shipping shipping city";
        isValidateDate = true;
      } else if (
        DeliveryShippingData?.ShippingShowState &&
        data?.ShippingState.value == "-1"
      ) {
        error_message = "Please select shipping shipping state";
        isValidateDate = true;
      } else if (
        DeliveryShippingData?.ShippingShowZipCode &&
        data?.ShippingZipCode.value == ""
      ) {
        error_message = "Please select shipping zip code";
        isValidateDate = true;
      }
    }
    if (DeliveryShippingData?.ShippingShowDate) {
      var Difference = Math.ceil(
        Math.abs(new Date(data?.ShippingDate.value) - new Date()) /
        (1000 * 60 * 60 * 24)
      );
      if (data?.ShippingDate.value == "") {
        error_message = "Please select shipping date";
        isValidateDate = true;
      } else if (
        data?.ShippingDate.value ==
        getSmallestDate(BeginEndDate?.MultipleDatesData)
      ) {
        error_message =
          "Please make sure that the delivery date is a future date.";
        isValidateDate = true;
      } else if (
        data?.shipping.options[data?.shipping.selectedIndex]
          .getAttribute("data-title")
          .includes("Free Delivery") &&
        Difference < 10
      ) {
        error_message =
          "Free shipping is not available when travel date is within ten days, please choose an alternative shipping method.";
        isValidateDate = true;
      }
    }

    if (isValidateDate)
      return {
        isDateVisible: true,
        err_date_msg: error_message,
      };
  }

  //ValidateBillingData
  if (data?.billingFirstName.value == "")
    return {
      isDateVisible: true,
      err_date_msg: "Please enter cardholder first name !",
    };

  if (data?.billingLastName.value == "")
    return {
      isDateVisible: true,
      err_date_msg: "Please enter cardholder last name !",
    };

  if (
    !data?.billingEmail.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return {
      isDateVisible: true,
      err_date_msg: "Please enter valid Cardholder email !",
    };

  let ccNum = data?.billingCardNumber.value.replaceAll(" ", "");
  if (isNaN(parseInt(ccNum))) {
    return {
      isDateVisible: true,
      err_date_msg: "Please enter valid cc number !",
    };
  } else {
    if (ccNum.length < 14) {
      return {
        isDateVisible: true,
        err_date_msg: "Incorrect credit card number!",
      };
    }
  }

  if (
    data?.billingExpireMonth.value == "0" ||
    data?.billingExpireYear.value == "0"
  )
    return {
      isDateVisible: true,
      err_date_msg: "Please select Expiration Date !",
    };

  if (data?.billingCardType.value == "0")
    return {
      isDateVisible: true,
      err_date_msg: "Please select valid card type !",
    };

  //ValidateCoupon
  var Coupon = data?.coupon.value;
  var isCouponValid = false;
  if (Coupon != "") {
    isCouponValid = await ApiGetCall(
      `/api/CouponCode?coupon=${Coupon}&b=${parseInt(
        bundles[0]?.Counter
      )}&linkid=${parseInt(Counter)}`
    ).then((result) => {
      try {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          if (responseRs == "invalid") return true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        //   setLoader(false);
      }
    });
  }
  if (isCouponValid)
    return {
      isDateVisible: true,
      err_date_msg: "Invalid coupon code !",
    };

  if (!data?.authorizationCheckbox.checked)
    return {
      isDateVisible: true,
      err_date_msg:
        "You must agree to the Terms & conditions in order to submit this order!",
    };

  return { isDateVisible: false, err_date_msg: "" };
};

export const FillData = (
  data,
  bundleSubLinkData,
  equipmentData,
  DeliveryShippingData,
  BeginEndDate
) => {
  var FillDataObject = [];
  var SBLData = bundleSubLinkData[0]; // bundleSubLinkData
  var BData = SBLData?.bundles[0]; // bundleData
  var ShippingData = data?.target?.shipping?.selectedOptions[0];

  var filterEquipment = FilterEquipmentData(
    BData?.equipments,
    equipmentData?.MultipleEquipmentsData,
    0
  );
  var AlreadyHaveSimShipping = equipmentData?.MultipleEquipmentsData.every(
    (obj) => obj.EquipmentBaseCode == "9999"
  );
  var ESimShipping = equipmentData?.MultipleEquipmentsData.every(
    (obj) => obj.EquipmentBaseCode == "3290"
  );
  var ob = new Object();
  ob.ContractType = SBLData?.contractType;
  ob.ProviderCode = SBLData?.ProviderCode;
  ob.txtSignupRep = data?.target?.signUpRepInfo?.value;
  ob.LinkTypeCode = SBLData?.LinkTypeCode;
  ob.AgentCode = SBLData?.AgentCode;
  ob.SubAgentCode = SBLData?.SubAgentCode;
  ob.bitCallPackageOverageProtection = false;
  ob.BundleId = BData?.Counter;

  var start_date = dateFormat(BeginEndDate?.MultipleDatesData[0]?.begin_Date);
  var end_date =
    BeginEndDate?.MultipleDatesData[0]?.end_Date != ""
      ? dateFormat(BeginEndDate?.MultipleDatesData[0]?.end_Date)
      : "";
  start_date = start_date + " 12:00:00 AM";
  end_date = end_date + " 12:00:00 AM";

  // var europePlanSelection = "";
  // var euroIDAndQty = "";
  // try {
  //   var euroPlanName = $(this).attr("europeplanname");
  //   var checkBoxId = $(this).attr("europecheckboxid");
  //   //	var euroQuantity=$(this).attr('quantity');
  //   var euroQuantity = "1";

  //   if (checkBoxId != undefined && checkBoxId != null) {
  //     euroIDAndQty = " " + checkBoxId + "-" + euroQuantity;
  //     europePlanSelection =
  //       " " + euroPlanName + " " + checkBoxId + "-" + euroQuantity;
  //   }
  // } catch (err) {
  //   console.log("europeError");
  //   console.log(err);
  // }

  // if (counter == 1) {
  //   ob.AccessoryIdAndQuantity = strAccessoryIdAndQuantity + "" + euroIDAndQty;
  // } else {
  //   ob.AccessoryIdAndQuantity = euroIDAndQty;
  // }
  ob.ccemail = data.target.billingEmail.value;
  ob.SessionID = "NC_WSC-BundleOrder";
  ob.BaseCode = ShippingData?.getAttribute("base-code")
    ? ShippingData?.getAttribute("base-code")
    : 100;
  ob.BaseNotes = ShippingData?.getAttribute("shipping-notes")
    ? ShippingData?.getAttribute("shipping-notes")
    : "";
  ob.bitCallPackageOverageProtection = false;
  ob.CallPackageCode = BData?.CallPackageCode;

  ob.CallPackageName = BData?.CallPackageName;
  ob.CCCode = null;
  ob.strCCExpDate =
    data.target.billingExpireMonth.value +
    "/" +
    data.target.billingExpireYear.value; // time and date not included

  ob.CCNum = data.target.billingCardNumber.value.replaceAll(" ", "");
  ob.CCTitle = data.target.billingCardType.value;
  ob.ClientCity = data.target.city.value;
  ob.ClientCountry =
    data.target.CountryData.selectedOptions[0].getAttribute("data-countryname");
  ob.clientemail = data.target.email.value;
  ob.ClientFirstName = data.target.FirstName.value;
  ob.ClientHomePhone1 = data.target.phoneNumber.value;
  ob.ClientHomePhone2 = "0";
  ob.ClientIP = ":1";
  ob.ClientLastName = data.target.lastName.value;
  ob.ClientMobile = data.target.phoneNumber.value;

  let CountryCode = data.target.CountryData.value;
  ob.ClientState =
    CountryCode == "1" || CountryCode == "7"
      ? data.target.StateData.value
      : "NA";

  ob.ClientStreet = data.target.streetAddress.value;
  ob.ClientZip = CountryCode != "4" ? data.target.ZipCode.value : "0";

  ob.CompanyCode = SBLData?.CompanyCode;
  ob.PageName = "";
  ob.CouponCode = data.target.coupon.value;
  ob.CreditEquipmentPurchase = null;
  ob.CustomerComment = data.target.ccNote.value;
  ob.DataPackageCode = BData?.ExtendedDataPackageCode;
  ob.DataPackageId = ob.DataPackageCode;
  ob.DataPackageName = BData?.ExtendedDataPackageName;
  ob.DataPackgeSize = BData?.PackageSize;
  ob.DepartureDate = start_date;
  ob.Deposit = SBLData?.DepositAmount;

  let maximumPeriod = SBLData?.maximumPeriod;

  let begin_date = dateFormat(BeginEndDate?.MultipleDatesData[0]?.begin_Date);
  let end_year = new Date(begin_date).getFullYear();
  let end_month = new Date(begin_date).getMonth();
  let end_day = new Date(begin_date).getDate();
  let endDate = new Date(end_year + 10, end_month, end_day);

  let endDateStr =
    (endDate.getMonth() > 8
      ? endDate.getMonth() + 1
      : "0" + (endDate.getMonth() + 1)) +
    "/" +
    (endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate()) +
    "/" +
    endDate.getFullYear();

  endDateStr = endDateStr + " 12:00:00 AM";
  if (
    (maximumPeriod == "" || maximumPeriod == undefined) &&
    (end_date == "" || end_date == undefined)
  ) {
    ob.EndDate = endDateStr;
  } else {
    ob.EndDate = end_date;
  }

  // ob.EquipmentCode = filterEquipment?.IsSim
  //   ? BData?.equipments?.filter(
  //       (obj) => obj.EquipmentCode != 9999 && obj.EquipmentCode != 3290
  //     )[0]?.EquipmentCode
  //   : filterEquipment?.EquipmentCode;
  ob.EquipmentCode = SBLData?.HaveSimEquipmentCode;
  ob.EquipmentModel = ob.EquipmentCode;
  ob.EquipmentName = filterEquipment?.name;
  ob.EquipmentNotes =
    filterEquipment?.name != null ? filterEquipment?.name : "";
  ob.FirstName = data.target.FirstName.value;
  ob.GroupMemberID = "";
  ob.GroupName = SBLData?.GroupName ? SBLData?.GroupName : null;
  ob.Hint = null;
  ob.Insurance = SBLData?.insurance == "included" ? true : false;
  ob.IsEquipmentSNS = filterEquipment?.IsSns;
  ob.IsKosher = filterEquipment?.kosher;
  ob.IsRequierdOperationSys = false;
  ob.IsSim = filterEquipment?.IsSim;
  ob.IsSmartPhone = filterEquipment?.IsSmartPhone;
  ob.KITD = false;
  ob.KITD_BLOCK_ID = null;
  ob.KITD_PlanCode = -1;
  ob.KNTName = "";
  ob.KNTRequired = -1;
  ob.LanguageCode = 1;
  ob.LastName = data.target.lastName.value;
  ob.ParentLink = BData?.ParentLink;
  ob.ParentOnlineOrderCode = null;

  ob.PhonesRequired = equipmentData?.Quantity;
  ob.PlanCode = BData?.PlanCode;
  ob.PlanName = BData?.PlanName;
  ob.ProductId = 1;
  ob.PurchaseEquipment = SBLData?.optPurchase;
  ob.ReferrerCounter = null;
  ob.ReferrerEmail = "";
  ob.RentalCode = null;
  ob.SetupFeeText = "No";

  if (AlreadyHaveSimShipping) {
    ob.ShipCity = "[have Sim]";
    ob.ShipCommercial = false;
    ob.ShipCountry = "Israel";
    ob.ShipDate = DeliveryShippingData?.ShippingShowDate
      ? dateFormat(data?.target?.ShippingDate?.value) + " 12:00:00 AM"
      : start_date;
    ob.shipemail = ob.clientemail;
    ob.ShipFee = 0;

    ob.ShipMethod = "ALREADY_HAVE_SIM";

    ob.ShipName = "[have Sim]";
    ob.ShipPhone = "0";

    ob.ShippingName = "ALREADY_HAVE_SIM";
    ob.ShipPostalCode = "[have sim]";
    ob.ShipState = "NA";
    ob.ShipStreet = "[have Sim]";
  } else if (ESimShipping) {
    ob.ShipCity = "[eSIM]";
    ob.ShipCommercial = false;
    ob.ShipCountry = "Israel";
    ob.ShipDate = DeliveryShippingData?.ShippingShowDate
      ? dateFormat(data?.target?.ShippingDate?.value) + " 12:00:00 AM"
      : start_date;
    ob.shipemail = ob.clientemail;
    ob.ShipFee = 0;

    ob.ShipMethod = "eSIM";

    ob.ShipName = "[eSIM]";
    ob.ShipPhone = "0";

    ob.ShippingName = "ALREADY_HAVE_SIM";
    ob.ShipPostalCode = "[eSIM]";
    ob.ShipState = "NA";
    ob.ShipStreet = "[eSIM]";
  } else if (!DeliveryShippingData?.isVisible) {
    ob.ShipCity = "[pickup]";
    ob.ShipCommercial = false;
    ob.ShipCountry = ShippingData?.getAttribute("countrie-name");
    ob.ShipDate = DeliveryShippingData?.ShippingShowDate
      ? dateFormat(data?.target?.ShippingDate?.value) + " 12:00:00 AM"
      : start_date;
    ob.shipemail = ob.clientemail;
    ob.ShipFee = ShippingData?.getAttribute("data-cost");
    ob.ShipId = ShippingData?.getAttribute("value");
    ob.ShipMethod = ShippingData?.getAttribute("shipmethod");
    // let shippingNameInput = ob.ClientFirstName + " " + ob.ClientLastName;
    ob.ShipName = "[pickup]";
    ob.ShipPhone = ob.ClientHomePhone1;

    ob.ShippingName = ShippingData?.getAttribute("data-title")
      ? ShippingData?.getAttribute("data-title")
      : "[pickup]";
    ob.ShipPostalCode = "[pickup]";
    ob.ShipState = "NA";
    ob.ShipStreet = "[pickup]";
  } else {
    if (DeliveryShippingData?.ShippingMethod == "UseBillingAddress") {
      ob.ShipCity = ob.ClientCity;
      ob.ShipCommercial = false;
      ob.ShipCountry = ob.ClientCountry;
      ob.ShipDate = DeliveryShippingData?.ShippingShowDate
        ? dateFormat(data?.target?.ShippingDate?.value) + " 12:00:00 AM"
        : start_date;
      ob.shipemail = ob.clientemail;
      ob.ShipFee = ShippingData?.getAttribute("data-cost");
      ob.ShipId = ShippingData?.getAttribute("value");
      ob.ShipMethod = ShippingData?.getAttribute("shipmethod");
      let shippingNameInput = ob.ClientFirstName + " " + ob.ClientLastName;
      ob.ShipName = shippingNameInput
        ? shippingNameInput
        : ShippingData?.getAttribute("data-title");
      ob.ShipPhone = ob.ClientHomePhone1;

      ob.ShippingName = ShippingData?.getAttribute("data-title")
        ? ShippingData?.getAttribute("data-title")
        : "[pickup]";
      ob.ShipPostalCode = ob.ClientZip;
      ob.ShipState = ob.ClientState;
      ob.ShipStreet = ob.ClientStreet;
    } else {
      ob.ShipCity = data?.target?.ShippingCity?.value
        ? data?.target?.ShippingCity?.value
        : "[pickup]";
      ob.ShipCommercial = false;
      ob.ShipCountry = ShippingData?.getAttribute("countrie-name");
      ob.ShipDate = DeliveryShippingData?.ShippingShowDate
        ? dateFormat(data?.target?.ShippingDate?.value) + " 12:00:00 AM"
        : start_date;
      ob.shipemail = data?.target?.billingEmail?.value;
      ob.ShipFee = ShippingData?.getAttribute("data-cost");
      ob.ShipId = ShippingData?.getAttribute("value");
      ob.ShipMethod = ShippingData?.getAttribute("shipmethod");
      let shippingNameInput = data?.target?.ShippingName?.value;
      ob.ShipName = shippingNameInput
        ? shippingNameInput
        : ShippingData?.getAttribute("data-title");
      ob.ShipPhone = data?.target?.ShippingPhoneNumber?.value;

      ob.ShippingName = ShippingData?.getAttribute("data-title")
        ? ShippingData?.getAttribute("data-title")
        : "[pickup]";
      ob.ShipPostalCode = DeliveryShippingData?.ShippingShowZipCode
        ? data?.target?.ShippingZipCode?.value
        : "[pickup]";
      ob.ShipState = DeliveryShippingData?.ShippingShowState
        ? data?.target?.ShippingState?.value
        : "[pickup]";
      ob.ShipStreet = data?.target?.ShippingStreetAddress?.value;
    }
  }

  ob.SMSPackageCode = BData?.SMSPackageCode;
  ob.SMSPackageCounter = ob.SMSPackageCode;
  ob.SMSPackageName = BData?.SMSPackageName;
  ob.Special = SBLData?.LinkTypeCode == 110 ? true : false;
  ob.StartDate = start_date;
  ob.SubLink = SBLData?.SubLink;
  ob.SublinkId = SBLData.Counter;
  ob.SurfAndSave = false;

  // let tags = "";
  // let countAlreadySim = 0;
  // let simString = "";
  // $(".simCount").each(function (index) {
  //   let simValue = $(this).find("input:checked").val();

  //   if (simValue == "9999") {
  //     countAlreadySim++;
  //   }
  // });
  // if (countAlreadySim > 0) {
  //   		   $('.simNoclone').each(function (index) {
  //   			   let simCardNum = $(this).find('input').val();
  //   			 simCardNum = simCardNum.replace(/\s/g, '');
  //   			   simString+= (index+1) +' '+'Special Order:Sim Number#: 89972' +simCardNum + '#' + '    ';
  //   		   });
  // }

  // var alreadyHaveSimNums = $(this).attr("simnumbers");
  // if (
  //   alreadyHaveSimNums != undefined &&
  //   alreadyHaveSimNums != "" &&
  //   alreadyHaveSimNums != " "
  // ) {
  //   simString = alreadyHaveSimNums;
  // }
  // if (counter == 1) {
  //   ob.Tag = simString + tags + europePlanSelection;
  // } else {
  //   ob.Tag = simString + europePlanSelection;
  // }

  ob.TermsCode = -1;
  ob.TermsName = null;
  ob.UserName = data.target.FirstName.value + " " + data.target.lastName.value;
  ob.UserStreet = SBLData?.AdminComment;

  var simArr = [];
  for (let i = 0; i < equipmentData?.MultipleEquipmentsData.length; i++) {
    let filterEquipments = FilterEquipmentData(
      BData?.equipments,
      equipmentData?.MultipleEquipmentsData,
      i
    );

    var SimDetails = new Object();
    SimDetails.KITD_PlanCode =
      BData?.kntCountries.length > 1
        ? equipmentData?.MultipleEquipmentsData[i]?.kntCountries
        : -1;
    SimDetails.SMSPackageName =
      BData?.smss.length > 1
        ? BData?.smss?.filter(
          (obj) =>
            obj.SmsPackageCode ==
            parseInt(equipmentData?.MultipleEquipmentsData[i]?.smss)
        )[0]?.SMSPackageName
        : "";

    if (SimDetails.KITD_PlanCode != -1) {
      SimDetails.KITD = true;
    } else {
      SimDetails.KITD = false;
    }

    SimDetails.CallPackageCode = ob.CallPackageCode;
    SimDetails.curST_TOP_Price = 0.0;
    SimDetails.DataPackageCode = ob.DataPackageCode;
    SimDetails.DataPackageId = ob.DataPackageCode;
    SimDetails.DataPackageName = ob.DataPackageName;
    SimDetails.DataPackgeSize = ob.DataPackgeSize;
    SimDetails.decST_TOP_GB = 0.0;
    SimDetails.EquipmentCode =
      filterEquipments?.EquipmentCode == 9999
        ? BData?.equipments?.filter(
          (data) =>
            data?.IsSim &&
            data?.EquipmentId != 9999 &&
            data?.EquipmentId != 3290 &&
            data?.EquipmentId != 3380
        )[0]?.EquipmentId
        : filterEquipments?.EquipmentCode;
    SimDetails.EquipmentModel = SimDetails.EquipmentCode;
    SimDetails.EquipmentName = filterEquipments?.name;
    SimDetails.EquipmentNotes =
      filterEquipments?.Notes != null ? filterEquipments?.Notes : "";
    SimDetails.Insurance = equipmentData?.MultipleEquipments
      ? equipmentData?.MultipleEquipmentsData[0]?.Insurance
      : equipmentData?.MultipleEquipmentsData[i]?.Insurance;

    var startDt = dateFormat(
      BeginEndDate?.MultipleDates
        ? BeginEndDate?.MultipleDatesData[0]?.begin_Date
        : BeginEndDate?.MultipleDatesData[i]?.begin_Date
    );
    var endDt = BeginEndDate?.MultipleDates
      ? BeginEndDate?.MultipleDatesData[0]?.end_Date
      : BeginEndDate?.MultipleDatesData[i]?.end_Date;
    endDt = endDt != "" ? dateFormat(endDt) : "";

    SimDetails.StartDate = startDt + " 12:00:00 AM";
    SimDetails.DepartureDate = startDt + " 12:00:00 AM";

    let begin_date2 = startDt;
    let end_year2 = new Date(begin_date2).getFullYear();
    let end_month2 = new Date(begin_date2).getMonth();
    let end_day2 = new Date(begin_date2).getDate();
    let endDate2 = new Date(end_year2 + 10, end_month2, end_day2);

    let endDateStr2 =
      (endDate2.getMonth() > 8
        ? endDate2.getMonth() + 1
        : "0" + (endDate2.getMonth() + 1)) +
      "/" +
      (endDate2.getDate() > 9 ? endDate2.getDate() : "0" + endDate2.getDate()) +
      "/" +
      endDate2.getFullYear();

    endDateStr2 = endDateStr2 + " 12:00:00 AM";
    if (
      (maximumPeriod == "" || maximumPeriod == undefined) &&
      (endDt == "" || endDt == undefined)
    ) {
      SimDetails.EndDate = endDateStr2;
    } else {
      SimDetails.EndDate = endDt;
    }

    //SimDetails.EndDate = endDt + " 12:00:00 AM";
    SimDetails.Img = "https://www.talknsave.us/images/OneSimForall.jpg";
    // SimDetails.Insurance = (insurance) ? true : false;

    // var isSns2 = equipmentData?.getAttribute("data-issns");
    // var isKosher2 = equipmentData?.getAttribute("data-kosher");
    // var isSim2 = equipmentData?.getAttribute("data-issim");
    SimDetails.IsEquipmentSNS = filterEquipments?.IsSns;
    SimDetails.IsKosher = filterEquipments?.kosher;
    SimDetails.IsSIM = filterEquipments?.IsSim;
    SimDetails.IsRequiredOperationSystem = false;

    var smsPkgCodeAndCounter = ob.SMSPackageCode;
    try {
      var smsPackakeCode = equipmentData?.MultipleEquipmentsData[i]?.smss;
      if (
        smsPackakeCode != undefined &&
        smsPackakeCode != "" &&
        smsPackakeCode != "0"
      ) {
        smsPkgCodeAndCounter = smsPackakeCode;
      }
    } catch (err) {
      smsPkgCodeAndCounter = BData?.SMSPackageCode;
    }
    SimDetails.SMSPackageCode = smsPkgCodeAndCounter;
    SimDetails.SMSPackageCounter = smsPkgCodeAndCounter;
    simArr.push(SimDetails);
  }
  ob.SimDetails = simArr;

  // let counter = 1;
  let strAccessoryIdAndQuantity = "";
  let tags = "";
  let simString = "";
  ob.optionalOrders = new Object();
  ob.AccessoryIdAndQuantity = "";

  for (let i = 0; i < equipmentData?.MultipleEquipmentsData.length; i++) {
    if (equipmentData?.MultipleEquipmentsData[i]?.EquipmentBaseCode == "9999")
      simString +=
        i +
        1 +
        " " +
        "Special Order:Sim Number#: 89972" +
        equipmentData?.MultipleEquipmentsData[i]?.AlreadyHaveSim?.replace(
          /\s/g,
          ""
        ) +
        "#" +
        "    ";
  }
  tags = simString;

  if (SBLData?.optRequestProgramName) {
    tags += "  School Name: " + data?.target?.nameOfIsraelProgram.value;
  }

  if (SBLData?.AdminComment == "BHLT") {
    tags +=
      "[" +
      data?.target?.TalmidName?.value +
      "," +
      " " +
      data?.target?.FatherName?.value +
      "," +
      " " +
      data?.target?.YeshivaName?.value +
      "," +
      " " +
      data?.target?.RabbiName?.value +
      "," +
      " " +
      data?.target?.YeshivaSelect?.selectedOptions[0].getAttribute("value") +
      "]";
  }

  var optionalOrderArray = [];
  $(".phonecard").each(function () {
    if ($(this).find('input[name="ifOptional"]').is(":checked")) {
      let OptionalValue = $(this).find('input[name="ifOptional"]');
      let OptionalQty = $(this).find('input[name="OptionalQty"]').val();
      let ClientCode = OptionalValue.attr("clientcode");
      let CouponCode = OptionalValue.attr("couponcode");
      let Deposit = OptionalValue.attr("deposit");
      let EquipmentCode = OptionalValue.attr("equipmentcode");
      let Insurance = OptionalValue.attr("insurance");
      let OptionalCode = OptionalValue.attr("optionalcode");
      let OptionalFeeDesc = OptionalValue.attr("optionalfeedesc");
      let OptionalImg = OptionalValue.attr("optionalimg");
      let OptionalName = OptionalValue.attr("optionalname");
      let PlanCode = OptionalValue.attr("plancode");
      let RequiredInsurance = OptionalValue.attr("requiredinsurance");
      let RequiredOperationSystem = OptionalValue.attr(
        "requiredoperationsystem"
      );
      RequiredInsurance = RequiredInsurance.trim();
      RequiredOperationSystem = RequiredOperationSystem.trim();
      var newObject = new Object();
      newObject.ClientCode = $.trim(ClientCode);
      newObject.CouponCode = $.trim(CouponCode);
      newObject.Deposit = $.trim(Deposit);
      newObject.Insurance = Insurance == "true" ? true : false;
      newObject.OptionalCode = $.trim(OptionalCode);
      newObject.OptionalFeeDesc = $.trim(OptionalFeeDesc);
      newObject.OptionalImg = $.trim(OptionalImg);
      newObject.OptionalName = $.trim(OptionalName);
      newObject.PlanCode = $.trim(PlanCode);
      newObject.Quantity = OptionalQty;
      newObject.EquipmentCode = $.trim(EquipmentCode);
      newObject.RequiredInsurance = RequiredInsurance == "true" ? true : false;
      newObject.RequiredOperationSystem =
        RequiredOperationSystem == "true" ? true : false;
      optionalOrderArray.push(newObject);

      // if (counter == 1) {
      if (parseInt(OptionalQty) > 0) {
        let quantity = "";
        let insurance = "";

        if (parseInt(OptionalQty) > 1) {
          quantity = "(Quantity:" + OptionalQty + ")";
        }
        if (Insurance == "true") {
          insurance = "Insuarance:" + true;
        }
        if (!(parseInt(PlanCode) > -1 && parseInt(EquipmentCode) > -1)) {
          strAccessoryIdAndQuantity += OptionalCode + "-" + OptionalQty + ",";
        }

        tags +=
          OptionalName +
          " " +
          quantity +
          " " +
          insurance +
          " " +
          OptionalCode +
          "-" +
          OptionalQty +
          "," +
          " ";
      }
      // }
    }
    // counter++;
  });

  if (optionalOrderArray.length > 0) {
    ob.optionalOrders = optionalOrderArray;
    ob.AccessoryIdAndQuantity = strAccessoryIdAndQuantity;
  }
  ob.Tag = tags;

  ob.newsletter = false;
  ob.UserCity = "NA";
  // console.log(ob);
  FillDataObject.push(ob);

  return FillDataObject;
};

const dateFormat = (date) => {
  var dates = date.split("-");
  return `${dates[1]}/${dates[2]}/${dates[0]}`;
};

const FilterEquipmentData = (BData, MultipleEquipmentsData, index) => {
  return BData?.filter(
    (obj) =>
      obj.EquipmentCode == MultipleEquipmentsData[index]?.EquipmentBaseCode
  )[0];
};

//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import { FaList, FaRegHeart } from "react-icons/fa";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.css";

const Sidebar = (props) => {
  const loggedInUser = window.localStorage.getItem("userData");
  const User = window.localStorage.getItem("user");

  var pages = [];
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [showRentals, setRentalsVisible] = useState(false);
  const [showRentalsUp, setRentalsVisibleUp] = useState(false);
  const [showRentalsDown, setRentalsVisibleDown] = useState(false);
  const showHideRentals = () => {
    showRentals ? setRentalsVisible(false) : setRentalsVisible(true);
    showRentalsUp ? setRentalsVisibleUp(false) : setRentalsVisibleUp(true);
    showRentalsDown
      ? setRentalsVisibleDown(false)
      : setRentalsVisibleDown(true);
  };
  let rentalNumber = "sc";

  var pageName = window.location.pathname.replace("/", "");
  pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);
  const isWrongDialedOrBundle = window.localStorage.getItem(
    "isWrongDialedOrBundle"
  );
  if (isWrongDialedOrBundle == "true") {
    pageName = pageName.split("/")[0];
    if (pageName == "Bundle-order") {
      pages = [
        {
          name: "Bundle order",
          icon: "/svgs/dashboardico.svg",
          href: "/bundle-order",
          slug: "bundle-order",
          cName: "dashboardMargin paddingright",
        },
      ];
    } else {
      pages = [
        {
          name: "Wrong dialled Numbers",
          icon: "/svgs/wrongDialed.svg",
          href: "#",
          slug: "wrong-dialed-calls",
          cName: "dashboardMargin paddingright",
        },
      ];
    }
  } else {
    if (!loggedInUser) {
      window.location.href = "/login";
    } else if (User == "Location Portal") {
      pages = [
        {
          name: "Place an order",
          icon: "/svgs/map-pin.svg",
          href: "/place-order",
          slug: "location",
          cName: "dashboardMargin paddingright",
        },
        {
          name: "Assign phone no.",
          icon: "/svgs/dashboardico.svg",
          href: "/assign-phone",
          slug: "assign-phone",
          cName: "dashboardMargin paddingright",
        },
        // {
        //   name: "Recent Order",
        //   icon: "/svgs/ResentOrder.svg",
        //   href: "/recent-order",
        //   slug: "recent-order",
        //   cName: "dashboardMargin paddingright",
        // },
        // {
        //   name: "Logistics Summary",
        //   icon: "/svgs/LogisticSummary.svg",
        //   href: "/logistic-summary",
        //   slug: "logistic-summary",
        //   cName: "dashboardMargin paddingright",
        // },
        // {
        //   name: "Logistic List",
        //   icon: "/svgs/LogisticList.svg",
        //   href: "/logistic-list?pn=0",
        //   slug: "logistic-list",
        //   cName: "dashboardMargin paddingright",
        // },
        {
          name: "Logout",
          icon: "/svgs/logout.svg",
          href: "/logout",
          slug: "logout",
          cName: "logoutMargin paddingright",
        },
      ];
    } else {
      var rentals = JSON.parse(loggedInUser)[0];
      //create initial menuCollapse state using useState hook

      //create a custom function that will change menucollapse state from false to true and true to false
      const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
      };
      pages = [
        {
          name: "dashboard",
          icon: "/svgs/dashboardico.svg",
          href: "/dashboard",
          slug: "dashboard",
          cName: "dashboardMargin paddingright",
        },
        {
          name: "Rentals",
          icon: "/svgs/rentals.svg",
          href: "/rentals",
          slug: "rental",
          cName: "rentalMargin paddingright",
        },
        {
          name: "Invoices",
          icon: "/svgs/invoices.svg",
          href: `/invoice?rc=${0}`,
          slug: "invoice",
          cName: "invoiceMargin paddingright",
        },
        // {
        //   name: "Usage",
        //   icon: "/svgs/usage.svg",
        //   href: `/usage?rc=${0}`,
        //   slug: "usage",
        //   cName: "usagelMargin paddingright",
        // },
        {
          name: "Orders",
          icon: "/svgs/orders.svg",
          href: `/orders?rc=${0}`,
          slug: "orders",
          cName: "orderlMargin paddingright",
        },
        // {
        //   name: "Troubleshoot",
        //   icon: "/svgs/troubleshoot.svg",
        //   href: "/#",
        //   slug: "troubleshoot",
        // },
        // {
        //   name: "Rental Settings",
        //   icon: "/svgs/rentalSettings.svg",
        //   href: "/#",
        //   slug: "rentalsetting",
        // },
        {
          name: "My Account",
          icon: "/svgs/myaccount.svg",
          href: "/account",
          slug: "account",
          cName: "myaccMargin paddingright",
        },
        {
          name: "Payment Methods",
          // icon: "/svgs/myaccount.svg",
          icon: "/svgs/credit-card.svg",
          href: "/payments",
          slug: "payments",
          cName: "myaccMargin paddingright",
        },
        {
          name: "Logout",
          icon: "/svgs/logout.svg",
          href: "/logout",
          slug: "logout",
          cName: "logoutMargin paddingright",
        },
      ];
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      try {
        rentalNumber = params.rc;
      } catch (err) {
        rentalNumber = "er";
      }
    }
  }

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              <a href="/dashboard">
                {menuCollapse && (
                  <img
                    src="/Tns-LogoSideBar.png"
                    style={{ marginTop: "10px" }}
                  ></img>
                )}
                {!menuCollapse && (
                  <img
                    src="/Tns-LogoSideBar.png"
                    style={{ marginTop: "10px" }}
                  ></img>
                )}
              </a>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {pages.map(function (page, i) {
                var returnValue;
                {
                  page.name == "Rentals" && loggedInUser != "Location Portal"
                    ? (returnValue = (
                        <MenuItem key={i} className="rentalsMenu">
                          <div onClick={showHideRentals}>
                            {" "}
                            <img
                              src="/svgs/rentals.svg"
                              key={i}
                              className={`${page.cName}`}
                            ></img>
                            Rentals
                            {showRentalsUp && (
                              <i className="fas fa-chevron-up rentalIcon"></i>
                            )}
                            {!showRentalsDown && (
                              <i className=" fas fa-chevron-down rentalIcon "></i>
                            )}
                          </div>
                          {pageName.toString().toLowerCase() ==
                          page.slug.toString().toLowerCase()
                            ? !showRentals &&
                              rentals.map((rental, j) => (
                                <>
                                  {rental.Status == "Active" ? (
                                    <a
                                      key={j}
                                      href={`/rental?rc=${rental.RentalCode}`}
                                    >
                                      <div
                                        key={j}
                                        className={`menuRentals p-2 m-2 ${
                                          rentalNumber == rental.RentalCode
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {" "}
                                        {rental.PhoneNumberStr}{" "}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))
                            : showRentals &&
                              rentals.map((rental, k) => (
                                <>
                                  {rental.Status == "Active" ? (
                                    <a
                                      key={k}
                                      href={`/rental?rc=${rental.RentalCode}`}
                                    >
                                      <div
                                        key={k}
                                        className={`menuRentals p-2 m-2 ${
                                          rentalNumber == rental.RentalCode
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {" "}
                                        {rental.PhoneNumberStr}{" "}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                        </MenuItem>
                      ))
                    : (returnValue = (
                        <a
                          key={i}
                          href={`${page.href}`}
                          className={`${page.cName} `}
                        >
                          <MenuItem
                            key={i}
                            className={
                              pageName?.toString()?.toLowerCase() ==
                                page.slug.toString().toLowerCase() ||
                              (page.slug == "orders" && pageName == "Order")
                                ? "active"
                                : ""
                            }
                          >
                            <img
                              key={i}
                              src={`${page.icon}`}
                              className={`${page.cName}`}
                            ></img>
                            {page.name.charAt(0).toUpperCase() +
                              page.name.slice(1)}
                          </MenuItem>{" "}
                        </a>
                      ));
                }
                return returnValue;
              })}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;

import React from "react";
import "./account.css";
import $ from "jquery";
import axios from "axios";
import PersonalDetails from "./PersonalDetails";
import AccountDetails from "./ApiControll";
import ChangePassword from "./ChangePassword";
import { getBase64, isValidRental } from "../Helper/HelperFun";

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentals: [],
      ppDetails: {},
      pDetailPage: "",
      securitySettingPage: "d-none",
      bColorPd: "borderColorPD",
      bColorSs: "",
      imageF: "",
      imgfile: "",
      profilePic: "/images/dummyUser.jpg",
      saveBtn: "d-none",
      icon: "/images/editIcon.png",
    };
  }

  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");

    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      this.setState({ rentals: foundUser[0][0] });
    }

    AccountDetails().then((result) => {
      const results = JSON.parse(result);
      const datas = results[0][0];
      // console.log(datas);
      if (datas === undefined || datas === "") {
        alert("Something went wrong");
      } else {
        this.setState({
          ppDetails: datas,
          profilePic:
            datas != null && datas.ProfileImage
              ? `//wpapi.talknsave.net/templateshtml/profileimages/${datas.ProfileImage}`
              : "/images/dummyUser.jpg",
        });
      }
    });
  }

  //const [imageF, setImage] = useState(null);
  onFileChange = (e) => {
    const file = e.target.files[0];
    if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(file.name)) {
      alert("Please select an image file only");
    } else {
      this.setState({
        saveBtn: "",
      });
      getBase64(file).then((base64) => {
        // localStorage.setItem("fileBase64", JSON.stringify(base64));
        this.setState({
          profilePic: URL.createObjectURL(file),
        });
      });
      // var file = document.querySelector("#Imgupload").files[0];
      // var reader = new FileReader();
      // var url = reader.readAsDataURL(file);
      // reader.onloadend = function (e) {
      //   this.setState({
      //     profilePic: reader.result,
      //   });
      // }.bind(this);
      this.setState({
        icon: "/images/cancelIcon.png",
        imageF: file,
      });
    }
  };

  SubmitData = (event) => {
    const formData = new FormData();
    formData.append("FormFile", this.state.imageF);
    formData.append("FileName", this.state.imageF.name);
    formData.append("AccountID", this.state.ppDetails.AccountID);
    axios
      .post(`//wpapi.talknsave.net/Image/ImageUpload`, formData)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ImageInput = () => {
    if (this.state.icon == "/images/editIcon.png") {
      $("#Imgupload").click();
    }
    if (this.state.icon == "/images/cancelIcon.png") {
      this.setState({
        saveBtn: "d-none",
      });
      var pDetail = this.state.ppDetails;
      this.setState({
        profilePic: `//wpapi.talknsave.net/templateshtml/profileimages/${pDetail.ProfileImage}`,
      });
      this.setState({ icon: "/images/editIcon.png" });
      $("#Imgupload").val("");
      // window.location.href = "/account";
    }
  };

  render() {
    return (
      <>
        <div className="AccountPageBody">
          <div className="row rowMargin">
            <div className=" AcctopContainer col-lg-12 col-md-12">
              <div className="row ms-1">
                <div className="col-lg-2 col-6">
                  <div
                    className={`MyAfont1 text-center ${this.state.bColorPd}`}
                    onClick={() => {
                      this.setState({
                        securitySettingPage: "d-none",
                        pDetailPage: "",
                        bColorPd: "borderColorPD",
                        bColorSs: "",
                      });
                    }}
                  >
                    Personal Details
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div
                    className={`MyAfont1 text-center SSmargin ${this.state.bColorSs}`}
                    onClick={() => {
                      this.setState({
                        pDetailPage: "d-none",
                        securitySettingPage: "",
                        bColorPd: "",
                        bColorSs: "borderColorPD",
                      });
                    }}
                  >
                    Security Settings
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row rowMargin ${this.state.pDetailPage}`}>
            <div className="col-lg-4 p-5">
              <div className="form-group" style={{ position: "relative" }}>
                <div>
                  <input
                    className="col-12 d-none"
                    id="Imgupload"
                    type="file"
                    onChange={this.onFileChange}
                    accept="image/*"
                  />
                </div>
                <div className="editContainer" onClick={this.ImageInput}>
                  <input
                    className="edit-icon"
                    type="image"
                    src={this.state.icon}
                    title="Change Profile Picture"
                  />
                </div>

                <input
                  className="accImg"
                  type="image"
                  src={`${this.state.profilePic}`}
                  height="125px"
                  width="125px"
                  alt="submit"
                />
              </div>
              <div className={`row ${this.state.saveBtn}`}>
                <div className="col-lg-5 m-2 p-0">
                  <button
                    type="submit"
                    className="btn btn-primary uploadBtn"
                    onClick={this.SubmitData.bind(this)}
                  >
                    <i className="fa fa-check-square-o" /> Upload
                  </button>
                </div>
              </div>

              <PersonalDetails />
            </div>
          </div>

          <div className={`row rowMargin ${this.state.securitySettingPage}`}>
            <ChangePassword accountemail={this.state.ppDetails.AccountName} />
          </div>
        </div>
      </>
    );
  }
}
export default MyAccount;

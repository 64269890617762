import React from "react";
import "./rentalcss.css";
import "../rentalPage_popup/rentalPage_popup.css";
import PopupModal from "../Common/Popup";
import VirtualNumber from "../rentalPage_popup/virtualNumber";

class RentalSmallCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="RSimgBox">
          <img src={this.props.img} alt="phone" />
        </div>
        <div className="SmallFont1">{this.props.name}</div>
        <div className="SmallFont2">{this.props.equipmentType}</div>
        <div className="update">
          {this.props.popupS == "Updatepopup" ? (
            <>
              <div className={this.props.popupS}>
                <PopupModal
                  type="submit"
                  value="Submit"
                  className=""
                  class="update"
                  btnName="Add"
                  size="ExtendRentalCardSize deleteCardDismissBtn"
                >
                  <VirtualNumber
                    virNum={this.props.VirNum}
                    newVirtualNo={this.props.newVirtualNo}
                    RentalCode={this.props.RentalCode}
                  />
                </PopupModal>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default RentalSmallCom;

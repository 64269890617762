import { ApiPostCall } from "../Functions/connector";

export default function AccountDetails(dfToken) {
  const token = window.localStorage.getItem("Token");
  var urlencoded = new URLSearchParams();
  if (dfToken != undefined && dfToken != null && dfToken != "") {
    urlencoded.append("Token", dfToken);
  } else {
    urlencoded.append("Token", token);
  }
  return ApiPostCall("/Profile/Details", urlencoded).then((result) => {
    if (result === undefined || result === "") {
      alert("Something went wrong");
    } else {
      return result;
    }
  });
}

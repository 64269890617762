import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function LPPopup(props) {
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        // onHide={props.setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import "./style.css";
import { ApiPostCall } from "../Functions/connector";
import { useNavigate } from "react-router-dom";

export default function BundleOrder() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const Submit = async (e) => {
    e.preventDefault();
    var groupCode = e.target.groupCode.value;
    var subLink = "";
    var parentLink = "";
    if (groupCode != "") {
      setErrorMsg("");
      if (groupCode.indexOf("-") > 0) {
        parentLink = groupCode.split("-")[0];
        subLink = groupCode.split("-")[1];
      } else {
        subLink = groupCode;
      }
      var urlencoded = new URLSearchParams();
      urlencoded.append("SubLink", subLink);
      urlencoded.append("ParentLink", parentLink);
      await ApiPostCall(
        "/RouterParserBundleOrder/GetSubLinkByParentSub",
        urlencoded
      ).then((result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = JSON.parse(result);
            // console.log(responseRs?.Status);
            if (responseRs?.Status == "Success") {
              navigate(`/bundle-order/${responseRs?.Data}`);
              //   setParentLinkData(JSON.parse(responseRs.Data)[0]);
            } else {
              setErrorMsg(responseRs?.Data);
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          // setLoader(false);
        }
      });
    } else {
      setErrorMsg("Please enter a group code");
    }
  };
  return (
    <React.Fragment>
      <div className="pageMargin">
        <div className="row">
          <div className="col-md-6 col-12 mt-md-0 mt-4">
            <div className="recentOrderContainer p-4">
              <div className="row">
                <div className="col-cus-2">
                  <div className="boxCircle">
                    <img src="./images/LocationPortal/box.svg" alt="" />
                  </div>
                </div>
                <div className="col-10 ro-font1 my-1 c142454 align-self-center">
                  Group Code
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 ro-font1 my-1 c142454">
                  <form onSubmit={(e) => Submit(e)}>
                    <div className="row">
                      <div className="col-9">
                        <div className="form-group">
                          <input
                            className="box w-100"
                            name="groupCode"
                            type="text"
                          />
                          <span className="mt-2 ps-1 text-danger h6">
                            {errorMsg}
                          </span>
                        </div>
                      </div>
                      <div className="col-3 ">
                        <button
                          type="submit"
                          className="btn bundleSubmitBtn w-100 mt-1"
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

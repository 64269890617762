import React from "react";
import "./App.css";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Auth/login";
import Dashboard from "./components/Common/dashobard";
import Logout from "./components/Auth/logout";
import ForgotPassword from "./components/Auth/forgotpassword";
import Register from "./components/Auth/register";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Rental from "./components/Common/rental";
import Invoice from "./components/Common/invoice";
import Usage from "./components/Common/usage";
//import Orders from "./components/Common/orders";
import OrdersTest from "./components/Common/OrdersTest";
import Sidebar from "./components/Oraganism/sidebar";
import Topbar from "./components/Oraganism/topbar";
import BillTopbar from "./components/Oraganism/billtopbar";
import PaymentMethods from "./components/Bills/paymentMethods";
import MyAccount from "./components/Account/myAccount";
import Cookies from "universal-cookie";
import Test from "./components/Test";
import LoginLP from "./components/AuthLocationPortal/LoginLP";
import AssignOrders from "./components/LocationPortalComp/AssignOrders";
import Location from "./components/LocationPortalComp/Location";
import PlaceOrder from "./components/LocationPortalComp/PlaceOrder";
import WrongDialledCall from "./components/WrongDialledCallComp/WrongDialledCall";
import PayBillFun from "./components/Bills/PayBillFun";
import LogisticsSummary from "./components/LocationPortalComp/LogisticsSummary";
import RecentOrders from "./components/LocationPortalComp/RecentOrders";
import LogisticList from "./components/LocationPortalComp/LogisticList";
import BundleOrder from "./components/BundleOrders/BundleOrder";
import BundleOrderPage from "./components/BundleOrders/BundleOrderPage";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      user: "",
      isWrongDialedOrBundle: false
    };
  }
  componentDidMount() {
    const cookies = new Cookies();

    var isUserActive = cookies.get("IsPersist");
    if (isUserActive == undefined || isUserActive == "") {
      localStorage.removeItem("user");
      localStorage.removeItem("Token");
      localStorage.removeItem("InvoicesData");
    } else {
      cookies.set("IsPersist", "yes", {
        maxAge: 3000, // Will expire after 5min (value is in number of sec.)
      });
    }
    const loggedInUser = window.localStorage.getItem("user");
    if (window.location.pathname.split("/")[1] == "wrong-dialed-calls" || window.location.pathname.split("/")[1] == "bundle-order") {
      window.localStorage.setItem("isWrongDialedOrBundle", true);
      this.setState({ isLoggedIn: false, isWrongDialedOrBundle: true });
    } else if (loggedInUser == "Location Portal") {
      this.setState({ isLoggedIn: true, user: "Location Portal" });
    } else if (loggedInUser) {
      this.setState({ isLoggedIn: true, user: "Normal User" });
    } else {
      this.setState({ isLoggedIn: false, user: "" });
    }
  }
  render() {
    return (
      <>
        {this.state.isLoggedIn && (
          <>
            <div className="">
              <Sidebar />
              <div className="col">
                <Topbar />
                <div className="topDivider"></div>
                <div className="pageBody">
                  {this.state.user == "Normal User" && (
                    <>

                      <Router>
                        <Routes>
                          <Route path="/login" element={<Login />} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/" element={<Dashboard />} />
                          <Route path="/logout" element={<Logout />} />
                          <Route
                            path="/forgotpassword"
                            element={<ForgotPassword />}
                          />
                          <Route path="/rental" element={<Rental />} />
                          <Route path="/invoice" element={<Invoice />} />
                          <Route path="/usage" element={<Usage />} />
                          <Route path="/orders" element={<OrdersTest />} />

                          <Route
                            path="/payments"
                            element={<PaymentMethods />}
                          />
                          <Route path="/account" element={<MyAccount />} />
                          <Route path="/test" element={<Test />} />
                        </Routes>
                      </Router>
                    </>
                  )}
                  {this.state.user == "Location Portal" && (
                    <>
                      <Router>
                        <Routes>
                          <Route path="/assign-phone" element={<AssignOrders />} />

                          <Route path="/place-order">
                            <Route index element={<Location />} />
                            <Route path=":subLinkId/:shipId" element={<PlaceOrder />} />
                          </Route>
                          {/* <Route path="/logistic-summary" element={<LogisticsSummary />} />
                          <Route path="/logistic-list" element={<LogisticList />} />
                          <Route path="/recent-order" element={<RecentOrders />} /> */}
                          {/* <Route path="/location" element={<Location />} />
                          <Route path="/place-order/:subLinkId/:shipId" element={<PlaceOrder />} /> */}
                          <Route path="/logout" element={<Logout />} />
                        </Routes>
                      </Router>

                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {(!this.state.isLoggedIn && this.state.isWrongDialedOrBundle) && (
          <div className="">
            <Sidebar />
            <div className="col">
              <Topbar />
              <div className="topDivider"></div>
              <div className="pageBody">
                <Router>
                  <Routes>
                    <Route path="/wrong-dialed-calls" element={<WrongDialledCall />} />
                    <Route path="/bundle-order">
                      <Route index element={<BundleOrder />} />
                      <Route path=":subLinkId" element={<BundleOrderPage />} />
                    </Route>
                  </Routes>
                </Router>
              </div>
            </div>
          </div>
        )}

        {(!this.state.isLoggedIn && !this.state.isWrongDialedOrBundle) && (
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/location-portal-login" element={<LoginLP />} />
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="*" element={<Login replace to="/404" />} />
              <Route path="/bill/:RC" element={<PayBillFun />} />
            </Routes>
          </Router>
        )}
      </>
    );
  }
}

export default App;

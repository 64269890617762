import React from "react";
import "./rentalcss.css";
import GeneralInfoCard from "./generalInfoCard";
import RentalS from "./rentalSmallCom";
import RentalL from "./rentalLargeCom";
import RentalM from "./rentalMobile";
import DataTableUsage from "../Oraganism/DataTableUsage";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import { convertUnixToDateTime, isValidRental } from "../Helper/HelperFun";

class Rentals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalDatas: {
        tblUsage: [],
        tblBills: [],
        tblDirect: [],
        tblLinkRentalCallPackage: [],
        tblLinkRentalExtendedDataPackage: [],
        tblLinkRentalMonthlyFees: [],
        tblLinkRentalOnceFees: [],
        tblLinkRentalSMSPackage: [],
        tblOrders: [],
        tblRentals: [],
        tblUsage_Data: [],
      },
      DateTime: "",
      Date: "",
      startDate: "",
      endDate: "",
      ccexp: { Month: "", Year: "" },
      RentCode: "",
      setLoader: true,
    };
  }

  componentDidMount() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let rentalNumber = parseInt(params.rc);
    if (Number.isInteger(rentalNumber)) {
      this.setState({ RentCode: rentalNumber });
      // if (rentalNumber) {
      const token = window.localStorage.getItem("Token");
      var urlencoded = new URLSearchParams();
      urlencoded.append("RentalCode", rentalNumber);
      // urlencoded.append("RentalCode", 383857);
      urlencoded.append("Token", token);
      ApiPostCall("/RouterParser/rental ", urlencoded).then((result) => {
        // console.log("got it");
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          // console.log(responseRs);
          this.setState({
            rentalDatas: {
              ...this.state.rentalDatas,
              tblBills: JSON.parse(responseRs.tblBills)[0],
              tblDirect: JSON.parse(responseRs.tblDirect)[0][0],
              tblLinkRentalCallPackage: JSON.parse(
                responseRs.tblLinkRentalCallPackage[0][0] != undefined
                  ? responseRs.tblLinkRentalCallPackage
                  : ""
              )[0][0],
              tblLinkRentalExtendedDataPackage: JSON.parse(
                responseRs.tblLinkRentalExtendedDataPackage
              )[0][0],
              tblLinkRentalMonthlyFees: JSON.parse(
                responseRs.tblLinkRentalMonthlyFees
              )[0][0],
              tblLinkRentalOnceFees: JSON.parse(
                responseRs.tblLinkRentalOnceFees
              )[0],
              tblLinkRentalSMSPackage: JSON.parse(
                responseRs.tblLinkRentalSMSPackage
              )[0][0],
              tblOrders: JSON.parse(responseRs.tblOrders)[0][0],
              tblRentals: JSON.parse(responseRs.tblRentals)[0][0],
              tblUsage: JSON.parse(responseRs.tblUsage)[0],
              tblUsage_Data: JSON.parse(responseRs.tblUsage_Data)[0],
            },
          });
          // console.log(this.state.rentalDatas);
          this.setState({ setLoader: false });
          $(".isGotData").text("1");

          if (this.state.rentalDatas.tblRentals) {
            var arr = [
              this.state.rentalDatas.tblRentals.UpdatedOn,
              this.state.rentalDatas.tblRentals.StartDate,
              this.state.rentalDatas.tblRentals.EndDate,
              this.state.rentalDatas.tblRentals.CCExpDate,
            ];
            for (var i = 0; i < arr.length; i++) {
              const splitDateTime = convertUnixToDateTime(arr[i]).split(",");

              if (i === 0) this.setState({ DateTime: splitDateTime });

              if (i === 1) this.setState({ startDate: splitDateTime });

              if (i === 2) this.setState({ endDate: splitDateTime });

              if (i === 3) {
                var DateSplit = splitDateTime[0].split("/");

                if (DateSplit[0] < 10) var ccM = 0 + DateSplit[0];
                else var ccM = DateSplit[0];

                var ccY = DateSplit[2].slice(2, 5);

                this.setState({
                  ccexp: { ...this.state.ccexp, Month: ccM, Year: ccY },
                });
              }
            }
            const date1 = new Date(this.state.startDate[0]);
            const date2 = new Date(this.state.endDate[0]);
            var today = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const currdate = Math.abs(today - date1);
            const diffDays2 = Math.ceil(currdate / (1000 * 60 * 60 * 24));
            var per = parseFloat(diffDays2 / diffDays) * 100;

            if (diffDays2 > diffDays) per = 100;

            this.setState({ rentalDateUsage: per });
          }
          var tblRentals = JSON.parse(responseRs.tblRentals)[0][0]
          if (params.er == '1'
            && !isValidRental(tblRentals)
            && tblRentals?.IsKosher == false
            && tblRentals?.IsStudent == false
            && tblRentals?.PlanName != "SIM for Life"
            && tblRentals?.Active == true) {
            $("#ExtendRentalPopup").click();
          }
        }
      });
      //}
    } else {
      this.setState({ RentCode: 0, setLoader: false });
    }
  }
  render() {
    const {
      tblUsage,
      tblBills,
      tblDirect,
      tblLinkRentalCallPackage,
      tblLinkRentalExtendedDataPackage,
      tblLinkRentalMonthlyFees,
      tblLinkRentalOnceFees,
      tblLinkRentalSMSPackage,
      tblOrders,
      tblRentals,
      tblUsage_Data,
    } = this.state.rentalDatas;
    if (tblDirect) {
      var VirT = tblDirect.Virtual;
      if (VirT) {
        if (typeof VirT !== "string") VirT = VirT.toString();
        var virtualNum = VirT.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
    var dataGb = 0;
    if (tblUsage_Data[0] && tblUsage_Data[0].FastDataGBAllowance) {
      dataGb = tblUsage_Data[0].FastDataGBAllowance;
    }

    let USLocale = Intl.NumberFormat("en-US");
    if (tblUsage_Data) {
      var usages_Data = tblUsage_Data;
      var TotalDailyUsageData = 0;
      var roamingData = 0;
      for (var j = 0; j < usages_Data.length; j++) {
        TotalDailyUsageData = usages_Data[j].RunningCycleUsageGB;
        roamingData = usages_Data[j].RunningCurrentCycleUsageGB_Roaming;
        break;
      }
    }
    if (tblUsage) {
      var usagesData = tblUsage;
      var min = tblLinkRentalCallPackage;
      var TotalDailyUsageSms = 0;
      var TotalDailyUsageLocalMinutes = 0;
      var TotalDailyUsageIntlMinutes = 0;
      var TotalMin = 0;
      for (var j = 0; j < usagesData.length; j++) {
        TotalDailyUsageSms += usagesData[j].SMS;
        TotalDailyUsageLocalMinutes = usagesData[j].DomesticRunningMinutes;
        TotalDailyUsageIntlMinutes = usagesData[j].IntlRunningMinutes;
        TotalMin += min.Minutes;
        break;
      }
      var datapadding = "pt-4";
      if (
        isNaN(TotalDailyUsageLocalMinutes) ||
        TotalDailyUsageLocalMinutes == 0
      ) {
        TotalDailyUsageLocalMinutes = 0;
        datapadding = "";
      }
      if (isNaN(TotalDailyUsageIntlMinutes)) {
        TotalDailyUsageIntlMinutes = 0;
      }
    }
    if (tblLinkRentalSMSPackage) {
      var localSmsUsageBar =
        (TotalDailyUsageSms / tblLinkRentalSMSPackage.CCPSMSBalance) * 100;
      if (tblLinkRentalSMSPackage.CCPSMSBalance < TotalDailyUsageSms) {
        var localSmsUsageBar = 100;
      }
    }
    if (tblLinkRentalCallPackage) {
      var localMinUsageBar = (TotalDailyUsageLocalMinutes / TotalMin) * 100;
      if (TotalMin < TotalDailyUsageLocalMinutes) {
        var localMinUsageBar = 100;
      }
    }

    return (
      <>
        {this.state.setLoader ? (
          <>
            <span className="d-none isGotData">0</span>
            <div className="loader_positon text-center">
              <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
              <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
                Loading...
              </div>
            </div>
          </>
        ) : this.state.RentCode == 0 ?
          <>
            <div className="row my-5">
              <div className="col-8 m-auto text-center RcardHeaderNumber">Rental not found!</div>
            </div>
          </> : (
            <div className="pageMarginR">
              <span className="d-none isGotData">0</span>
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 p-3">
                    <div className="row">
                      <div className="RcardHeader col-lg-3 col-5">Rental No.</div>
                      <div className="col-lg-9 col-7 RcardHeaderNumber">
                        {isValidRental(tblRentals)
                          ? "NA"
                          : tblRentals.PhoneNumber == undefined
                            ? 0
                            : tblRentals.PhoneNumber.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "$1-$2-$3"
                            )}
                        {isValidRental(tblRentals)
                          ? "NA"
                          : tblRentals.Active == true
                            ? " (Active)"
                            : " (Inactive)"}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 RdataContainer3 desktop">
                      <div className="row LargeStyle1 alignitemcenter">
                        <RentalL
                          data1={
                            isValidRental(this.state.RentCode)
                              ? // this.state.RentCode == 0
                              "NA"
                              : isValidRental(tblRentals)
                                ? "NA"
                                : tblRentals.IsKosher == true ||
                                  tblRentals.IsStudent == true ||
                                  tblRentals.PlanName == "SIM for Life"
                                  ? "Start Date"
                                  : "Rental Dates"
                          }
                          data2={
                            isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true ||
                                tblRentals.PlanName == "SIM for Life"
                                ? this.state.startDate[0] == undefined
                                  ? 0
                                  : this.state.startDate[0]
                                : `${this.state.startDate[0] == undefined
                                  ? 0
                                  : this.state.startDate[0]
                                } to ${this.state.endDate[0] == undefined
                                  ? 0
                                  : this.state.endDate[0]
                                }`
                          }
                          CardRentalCode={
                            isValidRental(this.state.RentCode)
                              ? ""
                              : this.state.RentCode
                          }
                          data3="Extend Rental"
                          img="/images/calendar.png"
                          popup={
                            isValidRental(tblRentals)
                              ? "d-none"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true ||
                                tblRentals.PlanName == "SIM for Life"
                                ? "d-none"
                                : tblRentals.Active == true
                                  ? "ExtendRentalpopup"
                                  : "d-none"
                          }
                          DisplayNone={
                            isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true ||
                                tblRentals.PlanName == "SIM for Life"
                                ? "d-none"
                                : ""
                          }
                          progressData={this.state.rentalDateUsage}
                          eDate={
                            this.state.endDate[0] == undefined
                              ? ""
                              : this.state.endDate[0]
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 RdataContainer3 mobile">
                      <div className="row LargeStyle1 alignitemcenter">
                        <RentalM
                          data1={
                            this.state.RentCode == 0
                              ? ""
                              : isValidRental(tblRentals)
                                ? "NA"
                                : tblRentals.IsKosher == true ||
                                  tblRentals.IsStudent == true
                                  ? "Start Date"
                                  : "Rental Dates"
                          }
                          data2={
                            isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true
                                ? this.state.startDate[0] == undefined
                                  ? 0
                                  : this.state.startDate[0]
                                : `${this.state.startDate[0] == undefined
                                  ? 0
                                  : this.state.startDate[0]
                                } to ${this.state.endDate[0] == undefined
                                  ? 0
                                  : this.state.endDate[0]
                                }`
                          }
                          CardRentalCode={
                            isValidRental(this.state.RentCode)
                              ? ""
                              : this.state.RentCode
                          }
                          data3="Extend Rental"
                          img="/images/calendar.png"
                          popup={
                            isValidRental(tblRentals)
                              ? "d-none"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true
                                ? "d-none"
                                : tblRentals.Active == true
                                  ? "ExtendRentalpopup"
                                  : "d-none"
                          }
                          DisplayNone={
                            isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.IsKosher == true ||
                                tblRentals.IsStudent == true
                                ? "d-none"
                                : ""
                          }
                          progressData={this.state.rentalDateUsage}
                          eDate={
                            this.state.endDate[0] == undefined
                              ? ""
                              : this.state.endDate[0]
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 RdataContainer">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 ">
                          <div className="RdataContainer1 setheight">
                            <RentalS
                              name="Equipment"
                              equipmentType={
                                isValidRental(tblRentals)
                                  ? "NA"
                                  : tblRentals.EquipmentName
                              }
                              img="/images/iphoneIcon.png"
                            />
                          </div>
                        </div>

                        <div className={`col-lg-6 col-md-6 col-12 heightpadding`}>
                          <div className="RdataContainer2 ">
                            <RentalS
                              name="Plan Type"
                              equipmentType={
                                isValidRental(tblLinkRentalCallPackage)
                                  ? ""
                                  : tblLinkRentalCallPackage.CallPackageDisplayName
                              }
                              img="/images/telephone.png"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-4">
                          <div className="RdataContainer1 setheight">
                            <RentalS
                              name="Client Type"
                              equipmentType={
                                isValidRental(tblRentals)
                                  ? "NA"
                                  : tblRentals.UserStreet
                              }
                              img="/images/clientType.png"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-6 ">
                      <div className="RdataContainer2 setheight">
                        <RentalS
                          // name="Voice"
                          equipmentType={`${
                            tblLinkRentalCallPackage == null ||
                            tblLinkRentalCallPackage == NaN
                              ? "NA"
                              : USLocale.format(
                                  tblLinkRentalCallPackage.Minutes
                                )
                          } min Local`}
                          img="/images/telephone.png"
                        />
                      </div>
                    </div> */}
                        {tblRentals.KNTPlanType == "SFL" ? (
                          ""
                        ) : (
                          <>
                            <div className={`col-lg-6 col-md-6 col-12 mt-4`}>
                              <div className="vnum">
                                <RentalS
                                  name="Virtual Number"
                                  equipmentType={
                                    isValidRental(tblDirect)
                                      ? "No Virtual Number"
                                      : virtualNum
                                  }
                                  popupS={
                                    isValidRental(tblDirect)
                                      ? "Updatepopup"
                                      : "d-none"
                                  }
                                  VirNum={virtualNum}
                                  img="/images/simIcon3.png"
                                  PlanTypeCode={tblRentals}
                                  RentalCode={
                                    isValidRental(tblRentals) ? "" : tblRentals
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {isValidRental(tblUsage_Data) ? (
                          ""
                        ) : isValidRental(tblRentals) ? (
                          "NA"
                        ) : tblRentals.IsKosher == true ||
                          tblRentals.IsStudent == true ? (
                          ""
                        ) : dataGb == 0 ? (
                          ""
                        ) : (
                          <>
                            {/* <div className="col-lg-3 col-md-3 col-6 ">
                          <div className="sms setheight">
                            <RentalS
                              name="SMS"
                              equipmentType={`${
                                tblLinkRentalSMSPackage == null
                                  ? "NA"
                                  : USLocale.format(
                                      tblLinkRentalSMSPackage
                                        .CCPSMSBalance
                                    )
                              }`}
                              img="/images/sms.png"
                            />
                          </div>
                        </div> */}
                            <div className="col-lg-3 col-md-3 col-6 mt-4">
                              <div className="data setheight">
                                <RentalS
                                  name="Data"
                                  equipmentType={
                                    isValidRental(tblUsage_Data)
                                      ? "0"
                                      : `${USLocale.format(dataGb)} GB`
                                  }
                                  img="/images/data.png"
                                  popupS="Data"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12  RdataContainer">
                  <div className="row"></div>
                </div> */}
                    {/* <div className="col-lg-12 col-md-12 RdataContainer3 desktop">
                  <div className="row LargeStyle1 alignitemcenter">
                    <RentalL
                      data1="Rental Dates"
                      data2={`${this.state.startDate[0]} to ${this.state.endDate[0]}`}
                      // data3="Extend Rental"
                      img="/images/calendar.png"
                      progressData={this.state.rentalDateUsage}
                    />
                  </div>
                </div> */}

                    <div className="col-lg-12 col-md-12 desktop ">
                      <div className=" RentalGeneralInfoCard">
                        <GeneralInfoCard
                          isFreezeSim="true"
                          title="Lost your sim?"
                          buttonLink="#"
                          buttonText="Freeze Now!"
                          bgImage="/images/worriedGirlRental.png"
                          body="Click on the button below to report a lost or stolen sim."
                          gCard="gCardR"
                          class="mt-1"
                          gContainer="gContainerR"
                          ProviderCodes={tblRentals.RentalProviderCode}
                        />
                      </div>
                    </div>
                    <div className="row desktop">
                      <div className="col mt-2 Unlimitedfair">
                        *Unlimited is a fair usage of 3000 minutes.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 p-3">
                    <div className="row RentalConrainer2">
                      <div className="TextHeader col-lg-12 col-md-12 col-12 ">
                        Payment Method
                      </div>
                      {/* <div className="TextHeader col-lg-2 col-md-2 col-3 ">
                    Usage
                  </div>
                  <div className=" col-lg col-md col-9 ">
                    remove this sample after update
                    <div className="boxCurrentBilling">
                      Current Billing Cycle
                    </div>
                    <select
                      className="form-select dropdown dd "
                      aria-label="Default select example"
                    >
                      <option defaultValue>Current Billing Cycle</option>
                      <option value="1">Previous month</option>
                      <option value="2">Current month</option>
                      <option value="2">Quarterly</option>
                    </select>
                  </div>
                  <div className=" col-lg-4 col-md-4 col-12 datetime">
                    <div className="row datetimeM mobile">
                      Last Update:
                      {`${this.state.DateTime[1]} ${this.state.DateTime[0]} GMT`}
                    </div>
                    <div className="row timeStyle desktop">
                      Last Update: {this.state.DateTime[1]}
                    </div>
                    <div className="row dateStyle desktop">
                      {`${this.state.DateTime[0]} 
                      `}
                    </div>
                  </div> */}
                    </div>
                    <div className="col-12 desktop">
                      <div className=" lm  RdataContainer6">
                        <div className="row LargeStyle6 alignitemcenter">
                          <RentalL
                            data1={`${isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.CCTitle.toLowerCase().trim() ==
                                "mastercard"
                                ? `Master Card - ${tblRentals.CCNumLFD == undefined
                                  ? 0
                                  : tblRentals.CCNumLFD
                                }`
                                : tblRentals.CCTitle.toLowerCase().trim() ==
                                  "visa"
                                  ? `Visa - ${tblRentals.CCNumLFD == undefined
                                    ? 0
                                    : tblRentals.CCNumLFD
                                  }`
                                  : `Discover - ${tblRentals.CCNumLFD == undefined
                                    ? 0
                                    : tblRentals.CCNumLFD
                                  }`
                              }`}
                            data2={`${isValidRental(tblRentals)
                              ? "NA"
                              : `Expire Date: ${this.state.ccexp.Month == ""
                                ? 0
                                : this.state.ccexp.Month
                              }/${this.state.ccexp.Year == ""
                                ? 0
                                : this.state.ccexp.Year
                              }`
                              }`}
                            CardNum={
                              tblRentals == null ? "NA" : tblRentals.PhoneNumber
                            }
                            CardRentalCode={
                              isValidRental(this.state.RentCode)
                                ? ""
                                : this.state.RentCode
                            }
                            progressData="100"
                            popup={
                              isValidRental(tblRentals) ? "d-none" : "CCpopup"
                            }
                            DisplayNone="d-none"
                            img={
                              isValidRental(tblRentals)
                                ? ""
                                : tblRentals.CCTitle.toLowerCase().trim() ==
                                  "mastercard"
                                  ? "/images/RentalMasterCard.png"
                                  : tblRentals.CCTitle.toLowerCase().trim() ==
                                    "visa"
                                    ? "/images/RentalVisa.png"
                                    : "/images/RentalDiscover.png"
                            }
                          />
                          {/* <div className="col-9"><h5>Data Usage</h5></div>
                                        <div className=" col-2 "><h6>10.5GB/20GB</h6></div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mobile">
                      <div className=" lm p-3 RdataContainer6">
                        <div className="row LargeStyle6 alignitemcenter">
                          <RentalM
                            data1={`${isValidRental(tblRentals)
                              ? "NA"
                              : tblRentals.CCTitle.toLowerCase().trim() ==
                                "mastercard"
                                ? `Master Card - ${tblRentals.CCNumLFD == undefined
                                  ? 0
                                  : tblRentals.CCNumLFD
                                }`
                                : tblRentals.CCTitle.toLowerCase().trim() ==
                                  "visa"
                                  ? `Visa - ${tblRentals.CCNumLFD == undefined
                                    ? 0
                                    : tblRentals.CCNumLFD
                                  }`
                                  : `Discover - ${tblRentals.CCNumLFD == undefined
                                    ? 0
                                    : tblRentals.CCNumLFD
                                  }`
                              }`}
                            data2={`${isValidRental(tblRentals)
                              ? "NA"
                              : `Expire Date: ${this.state.ccexp.Month == ""
                                ? 0
                                : this.state.ccexp.Month
                              }/${this.state.ccexp.Year == ""
                                ? 0
                                : this.state.ccexp.Year
                              }`
                              }`}
                            CardNum={
                              isValidRental(tblRentals)
                                ? "NA"
                                : tblRentals.PhoneNumber
                            }
                            CardRentalCode={
                              isValidRental(this.state.RentCode)
                                ? ""
                                : this.state.RentCode
                            }
                            progressData="100"
                            popup={
                              isValidRental(tblRentals) ? "d-none" : "CCpopup"
                            }
                            DisplayNone="d-none"
                            img={
                              isValidRental(tblRentals)
                                ? ""
                                : tblRentals.CCTitle.toLowerCase().trim() ==
                                  "mastercard"
                                  ? "/images/RentalMasterCard.png"
                                  : tblRentals.CCTitle.toLowerCase().trim() ==
                                    "visa"
                                    ? "/images/RentalVisa.png"
                                    : "/images/RentalDiscover.png"
                            }
                          />
                          {/* <div className="col-9"><h5>Data Usage</h5></div>
                                        <div className=" col-2 "><h6>10.5GB/20GB</h6></div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 mobile">
                  <div className=" lm p-3 RdataContainer6">
                    <div className="row LargeStyle6 alignitemcenter">
                      <RentalM
                        data1="Data Usage"
                        data2={`${
                          tblUsage_Data == null
                            ? "NA"
                            : USLocale.format(TotalDailyUsageData)
                        }`}
                        // data3="All Data"
                        progressData="100"
                        DisplayNone="d-none"
                        img="/images/BigDataRed.png"
                      />
                      <div className="col-9"><h5>Data Usage</h5></div>
                                        <div className=" col-2 "><h6>10.5GB/20GB</h6></div>
                    </div>
                  </div>
                </div> */}

                    {TotalDailyUsageLocalMinutes == 0 &&
                      TotalDailyUsageData == 0 &&
                      TotalDailyUsageIntlMinutes == 0 &&
                      roamingData == 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="row RmarginTop">
                          <div className="TextHeader col-lg-12 col-md-12 col-12 pb-2">
                            Usage
                          </div>
                        </div>
                        {TotalDailyUsageLocalMinutes == 0 &&
                          TotalDailyUsageData == 0 ? (
                          ""
                        ) : (
                          <div className="col-12 p-0  desktop">
                            <div className=" lm RdataContainer4 ">
                              {TotalDailyUsageLocalMinutes == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle2 alignitemcenter">
                                    <RentalL
                                      data1="Local Minutes"
                                      data2={
                                        (tblUsage_Data &&
                                          this.state.rentalDatas
                                            .tblLinkRentalCallPackage) == null
                                          ? "NA"
                                          : `${this.state.rentalDatas
                                            .tblUsage_Data == null
                                            ? "NA"
                                            : USLocale.format(
                                              TotalDailyUsageLocalMinutes
                                            )
                                          } min`
                                        // ${
                                        //   tblLinkRentalCallPackage == null
                                        //     ? "NA"
                                        //     : USLocale.format(TotalMin)
                                        // }min
                                      }
                                      data3=""
                                      popup="d-none"
                                      img="/images/BigTele.png"
                                      DisplayNone="d-none"
                                      progressData={localMinUsageBar}
                                    />
                                  </div>
                                </>
                              )}
                              {TotalDailyUsageData == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div
                                    className={`row LargeStyle3 ${datapadding} alignitemcenter`}
                                  >
                                    <RentalL
                                      data1="Data Usage"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : `${USLocale.format(
                                          TotalDailyUsageData
                                        )} GB`
                                        }`}
                                      progressData="100"
                                      popup={
                                        "d-none"
                                        // isValidRental(
                                        //   tblRentals
                                        // )
                                        //   ? "d-none"
                                        //   : "DataUsagepopup"
                                      }
                                      DisplayNone="d-none"
                                      img="/images/BigDataRed.png"
                                      rentalData={tblRentals}
                                      CardRentalCode={
                                        isValidRental(this.state.RentCode)
                                          ? ""
                                          : this.state.RentCode
                                      }
                                    />
                                    {/* <RentalL
                  data1="Local Text"
                  data2={`${
                    tblUsage == null
                      ? "NA"
                      : TotalDailyUsageSms
                  }/${
                    tblLinkRentalSMSPackage == null
                      ? "NA"
                      : tblLinkRentalSMSPackage.CCPSMSBalance
                  }`}
                  // data3="All Texts"
                  img="/images/BigSmsIcon.png"
                  progressData={localSmsUsageBar}
                /> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {TotalDailyUsageLocalMinutes == 0 &&
                          TotalDailyUsageData == 0 ? (
                          ""
                        ) : (
                          <div className="col-12 p-0  mobile">
                            <div className=" lm p-3 RdataContainer4 ">
                              {TotalDailyUsageLocalMinutes == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle2 alignitemcenter">
                                    <RentalM
                                      data1="Local Minutes"
                                      data2={
                                        (tblUsage_Data &&
                                          this.state.rentalDatas
                                            .tblLinkRentalCallPackage) == null
                                          ? "NA"
                                          : `${this.state.rentalDatas
                                            .tblUsage_Data == null
                                            ? "NA"
                                            : USLocale.format(
                                              TotalDailyUsageLocalMinutes
                                            )
                                          }min`
                                        // ${
                                        //   tblLinkRentalCallPackage == null
                                        //     ? "NA"
                                        //     : USLocale.format(TotalMin)
                                        // }min
                                      }
                                      popup="d-none"
                                      img="/images/BigTele.png"
                                      DisplayNone="d-none"
                                      progressData={localMinUsageBar}
                                    />
                                  </div>
                                </>
                              )}

                              {TotalDailyUsageData == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div
                                    className={`row LargeStyle3 ${datapadding} alignitemcenter`}
                                  >
                                    <RentalM
                                      data1="Data Usage"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : `${USLocale.format(
                                          TotalDailyUsageData
                                        )} GB`
                                        }`}
                                      progressData="100"
                                      popup={
                                        "d-none"
                                        // isValidRental(
                                        //   tblRentals
                                        // )
                                        //   ? "d-none"
                                        //   : "DataUsagepopup"
                                      }
                                      DisplayNone="d-none"
                                      img="/images/BigDataRed.png"
                                      rentalData={tblRentals}
                                      CardRentalCode={
                                        isValidRental(this.state.RentCode)
                                          ? ""
                                          : this.state.RentCode
                                      }
                                    />
                                    {/* <RentalM
                  data1="Local Text"
                  data2={`${
                    tblUsage == null
                      ? "NA"
                      : TotalDailyUsageSms
                  }/${
                    tblLinkRentalSMSPackage == null
                      ? "NA"
                      : tblLinkRentalSMSPackage.CCPSMSBalance
                  }`}
                  // data3="All Texts"
                  img="/images/BigSmsIcon.png"
                  progressData={localSmsUsageBar}
                /> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {TotalDailyUsageIntlMinutes == 0 && roamingData == 0 ? (
                          ""
                        ) : (
                          <div className="col-12 desktop">
                            <div className=" lm p-3 RdataContainer7 ">
                              {TotalDailyUsageIntlMinutes == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle4 alignitemcenter">
                                    <RentalL
                                      data1="International Minutes"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : USLocale.format(
                                          TotalDailyUsageIntlMinutes
                                        )
                                        } min`}
                                      data3=""
                                      popup="d-none"
                                      progressData="100"
                                      DisplayNone="d-none"
                                      img="/images/bigPhoneLG.png"
                                    />
                                  </div>
                                </>
                              )}

                              {roamingData == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle5 pt-4 alignitemcenter">
                                    <RentalL
                                      data1="Roaming"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : USLocale.format(roamingData)
                                        } GB`}
                                      // data3="All Data"
                                      popup="d-none"
                                      DisplayNone="d-none"
                                      img="/images/bigDataBlue.png"
                                    />
                                    {/* <RentalL
                        data1="International Text"
                        data2="180/200"
                        data3="All Texts"
                        img="/images/BigSmsIcon2.png"
                      /> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {TotalDailyUsageIntlMinutes == 0 && roamingData == 0 ? (
                          ""
                        ) : (
                          <div className="col-12 mobile">
                            <div className=" lm p-3 RdataContainer7">
                              {TotalDailyUsageIntlMinutes == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle4 alignitemcenter">
                                    <RentalM
                                      data1="International Minutes"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : USLocale.format(
                                          TotalDailyUsageIntlMinutes
                                        )
                                        }min`}
                                      data3=""
                                      popup="d-none"
                                      progressData="100"
                                      DisplayNone="d-none"
                                      img="/images/bigPhoneLG.png"
                                    />
                                  </div>
                                </>
                              )}

                              {roamingData == 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="row LargeStyle5 pt-4 alignitemcenter">
                                    <RentalM
                                      data1="Roaming"
                                      data2={`${tblUsage_Data == null
                                        ? "NA"
                                        : USLocale.format(roamingData)
                                        } GB`}
                                      // data3="All Data"
                                      popup="d-none"
                                      DisplayNone="d-none"
                                      img="/images/bigDataBlue.png"
                                    />
                                    {/* <RentalM
                        data1="International Text"
                        data2="180/200"
                        data3="All Texts"
                        img="/images/BigSmsIcon2.png"
                      /> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="col-lg-12 col-md-12">
                          <div className="">
                            <div className="TextHeader pb-2">Recent Charges</div>
                            <DataTableUsage RNum={tblLinkRentalOnceFees} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 desktop">
                  <div className=" lm p-3 RdataContainer6">
                    <div className="row LargeStyle6 alignitemcenter">
                      <RentalL
                        data1="Data Usage"
                        data2={`${
                          tblUsage_Data == null
                            ? "NA"
                            : USLocale.format(TotalDailyUsageData)
                        }`}
                        // data3="All Data"
                        progressData="100"
                        DisplayNone="d-none"
                        img="/images/BigDataRed.png"
                      />
                      <div className="col-9"><h5>Data Usage</h5></div>
                                        <div className=" col-2 "><h6>10.5GB/20GB</h6></div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mobile">
                  <div className=" lm p-3 RdataContainer6">
                    <div className="row LargeStyle6 alignitemcenter">
                      <RentalM
                        data1="Data Usage"
                        data2={`${
                          tblUsage_Data == null
                            ? "NA"
                            : USLocale.format(TotalDailyUsageData)
                        }`}
                        // data3="All Data"
                        progressData="100"
                        DisplayNone="d-none"
                        img="/images/BigDataRed.png"
                      />
                      <div className="col-9"><h5>Data Usage</h5></div>
                                        <div className=" col-2 "><h6>10.5GB/20GB</h6></div>
                    </div>
                  </div>
                </div> */}

                    {/* <div className="col-12 desktop">
                  <div className=" lm p-3 RdataContainer7">
                    <div className="row LargeStyle7 alignitemcenter">
                      <RentalL
                        data1="Roaming"
                        data2="10.5GB/20GB"
                        // data3="All Data"
                        img="/images/BigDataGreen.png"
                      />
                      <div className="col-9"><h5>Roaming</h5></div>
                                        <div className=" col-2 "><h6>17.5GB/20GB</h6></div>
                    </div>
                  </div>
                </div>

                <div className="col-12  mobile">
                  <div className=" lm p-3 RdataContainer7">
                    <div className="row LargeStyle7 alignitemcenter">
                      <RentalM
                        data1="Roaming"
                        data2="10.5GB/20GB"
                        // data3="All Data"
                        img="/images/BigDataGreen.png"
                      />
                      <div className="col-9"><h5>Roaming</h5></div>
                                        <div className=" col-2 "><h6>17.5GB/20GB</h6></div>
                    </div>
                  </div>
                </div> */}

                    <div className="col-lg-12 col-md-12 mobile">
                      <GeneralInfoCard
                        isFreezeSim="true"
                        title="Lost your sim?"
                        buttonLink="#"
                        buttonText="Freeze Now!"
                        bgImage="/images/worriedGirlRental.png"
                        body="Click on the button below to report a lost or stolen sim."
                        gCard="gCardR"
                        class="mt-1"
                        gContainer="gContainerR"
                        ProviderCodes={tblRentals.RentalProviderCode}
                      />
                    </div>
                    <div className="row mobile">
                      <div className="col p-3 Unlimitedfair">
                        *Unlimited is a fair usage of 3000 minutes.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

export default Rentals;

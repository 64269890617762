import { object } from "yup";
import GetHeightWidth from "./GetHeightWidth";

export function isValidRental(value) {
  var valid = false;

  if (
    value == null ||
    value == undefined ||
    value == "" ||
    value == 0 ||
    value == "0" ||
    value == "NULL" ||
    value == "null"
  ) {
    valid = true;
  } else {
    valid = false;
  }

  return valid;
}

export function convertUnixToDateTime(value) {
  return new Date(
    parseInt(value?.replace("/Date(", "").replace(")/", ""))
  ).toLocaleString("en-US", {
    hour12: true,
  });
}

export function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i][prop]);
  }
  return newArray;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export const getAfterTenYears = () => {
  var year = new Date().getFullYear();
  var years = [];
  for (var i = 0; i <= 9; i++) {
    years.push(year++);
  }
  return years;
};
export const getWidth = () => {
  const { width } = GetHeightWidth();
  var actualWidth = 0;
  if (width < 700) actualWidth = width - 40;
  else if (width < 1000) actualWidth = width - 225;
  else if (width <= 1366) actualWidth = width - 250;
  else if (width <= 1517) actualWidth = width - 275;
  else if (width <= 1707) actualWidth = width - 300;
  else if (width <= 1821) actualWidth = width - 325;
  else if (width <= 2049) actualWidth = width - 350;
  else if (width < 2732) actualWidth = width - 375;
  return actualWidth;
};

export const clickAndDrag = (selector) => {
  const slider = document.querySelector(selector);
  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener("mousedown", (e) => {
    isDown = true;
    slider.classList.add("active");
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener("mouseleave", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mouseup", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mousemove", (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3;
    slider.scrollLeft = scrollLeft - walk;
  });
};

export const termServiceFunction = (min) => {
  if (min != null) {
    var sTxtPeriod;
    var min = min?.split("|");
    if (min[1] == "d") {
      sTxtPeriod = "Minimum Length of Contract: " + min[0] + " days.";
    } else if (min[1] == "m") {
      sTxtPeriod = "Minimum Length of Contract: " + min[0] + " months.";
    }
    return sTxtPeriod;
  }
};

export const setDatesFunction = (data) => {
  var {
    dateProgramStart,
    dateLeaveIL,
    optLockProgramDates,
    optLeaveILrequest,
  } = data;
  var obj = new Object();
  obj.isStartDateVisible = true;
  obj.isEndDateVisible = true;
  obj.StartDateValue = "";
  obj.EndDateValue = "";
  if (dateProgramStart != null) {
    var dLockProgramStartDate = new Date(dateProgramStart);
    if (dLockProgramStartDate < new Date()) {
      dLockProgramStartDate = new Date();
    }
    dLockProgramStartDate = dLockProgramStartDate
      .toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/");
    obj.StartDateValue =
      dLockProgramStartDate[2] +
      "-" +
      dLockProgramStartDate[1] +
      "-" +
      dLockProgramStartDate[0];
    if (optLockProgramDates) obj.isStartDateVisible = false;
  }

  if (dateLeaveIL != null) {
    var dLockProgramEndDate = new Date(dateLeaveIL);
    if (dLockProgramEndDate < new Date()) {
      dLockProgramEndDate = null;
    } else {
      dLockProgramEndDate = dLockProgramEndDate
        .toLocaleDateString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");
      obj.EndDateValue =
        dLockProgramEndDate[2] +
        "-" +
        dLockProgramEndDate[1] +
        "-" +
        dLockProgramEndDate[0];
    }
    if (optLockProgramDates) obj.isEndDateVisible = false;
  }
  if (!optLeaveILrequest) obj.isEndDateVisible = false;

  return obj;
};

export const ChangeCountryFunction = (ddValue, BundleData) => {
  var obj = new Object();
  obj.StateList = [];
  obj.ShowState = false;
  obj.ShowZipCode = false;

  switch (ddValue) {
    case "1":
      obj.StateList = BundleData?.UsaStates;
      obj.ShowState = true;
      obj.ShowZipCode = true;
      break;
    case "7":
      obj.StateList = BundleData?.CanadaStates;
      obj.ShowState = true;
      obj.ShowZipCode = true;
      break;
    case "4":
      obj.StateList = [];
      obj.ShowState = false;
      obj.ShowZipCode = false;
      break;

    default:
      obj.StateList = [];
      obj.ShowState = false;
      obj.ShowZipCode = true;
      break;
  }
  return obj;
};

export const InputNumberGap = (value, gap) => {
  if (value != undefined) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, gap);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }
};

export const getNineYear = () => {
  var year = new Date().getFullYear();
  var years = [];
  for (var i = 0; i <= 9; i++) {
    years.push(year++);
  }
  return years;
};

export const getSmallestDate = (arrayOfDates) => {
  if (arrayOfDates.length > 0) {
    let smallestDate = arrayOfDates[0]?.begin_Date;
    for (const obj of arrayOfDates) {
      if (obj.begin_Date < smallestDate) {
        smallestDate = obj.begin_Date;
      }
    }
    return smallestDate;
  }
};

const BaseUrl = "//wpapi.talknsave.net";
export async function ApiPostCall(endpoint, payload, headerValue = "application/x-www-form-urlencoded") {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", headerValue);
  var urlencoded = payload;

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${BaseUrl}${endpoint}`, requestOptions);
    const result_1 = await response.text();
    return result_1;
  } catch (error) {
    return error;
  }
}
export async function ApiGetCall(endpoint) {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(`${BaseUrl}${endpoint}`, requestOptions);
    const result_1 = await response.text();
    return result_1;
  } catch (error) {
    return error;
  }
}

import React from "react";
import "./ChnagePlan.css";
import $ from "jquery";
class ChangePlanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  Dismiss = () => {
    $(".changeDetailsDismissBtn").find("#closebtn2").click();
  };

  render() {
    return (
      <>
        <div className="details">
          <div className=" text-center font1">Plan Changed!</div>
        </div>
        <div className="details">
          <div className="  font2">
            At eget urna faucibus tincidunt nisl ipsum et. Duis donec aenean
            etiam viverra massa dictum purus potenti facilisis. Sollicitudin sed
            sapien.
          </div>
        </div>

        <div className="row mobileCenter">
          <div className="col-lg-12 col-md-12 text-center">
            <button className="dismissbtnDetails" onClick={this.Dismiss}>
              Dismiss
            </button>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg">
            <button type="submit" className="btn btn applybtn2">
              <div className="applyFont">Close</div>
            </button>
          </div>
        </div> */}
      </>
    );
  }
}
export default ChangePlanDetails;

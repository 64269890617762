import React from "react";
import axios from "axios";
import $ from "jquery";
import "../Auth/style.css";
import { ApiPostCall } from "../Functions/connector";
import FooterLinks from "../Auth/footerlinks";
import Loader from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "universal-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
class LoginLP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      PasswordType: "password",
      isLoader: false,
    };
  }

  userLogin = async (e) => {
    e.preventDefault();
    const email = this.state.email;
    const pwd = this.state.password;
    if (email.trim() == "") {
      this.setState({
        InvalidEmail: true,
      });
      return;
    } else if (pwd.trim() == "") {
      this.setState({
        InvalidEmail: false,
        InvalidPwd: true,
      });
      return;
    } else {
      this.setState({
        InvalidEmail: false,
        InvalidPwd: false,
      });
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("UserEmail", email);
    urlencoded.append("Password", pwd);

    this.setState({ isLoader: true });
    this.setState({
      IsError: false,
    });

    await ApiPostCall("/AuthLocationPortal/login", urlencoded).then(
      (result) => {
        // console.log("got it", result);
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          console.log(responseRs);
          if (responseRs.Data == "InvalidEmailPwd") {
            this.setState({
              IsError: true,
            });
          } else {
            const cookies = new Cookies();
            cookies.set("IsPersist", "yes", {
              maxAge: 3000 /* Will expire after 5min (value is in number of sec.)
             || replace it with (300) when whole work is completed*/,
            });
            window.localStorage.setItem("user", responseRs?.Result);
            window.localStorage.setItem("userData", responseRs?.Data);
            window.localStorage.setItem("isWrongDialedOrBundle", false);
            const encodedString = base64_encode(`${email}:${pwd}`);
            window.localStorage.setItem("Token", encodedString);
            window.location.href = "/assign-phone";
          }
        }
        this.setState({ isLoader: false });
      }
    );
  };
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangePwd(e) {
    this.setState({ password: e.target.value });
  }
  HidePassword(e) {
    this.setState({
      IsShowPassword: false,
      PasswordType: "password",
    });
  }
  ShowPassword(e) {
    this.setState({
      IsShowPassword: true,
      PasswordType: "text",
    });
  }
  render() {
    return (
      <div className="row m-0 loginPageBody">
        <div className="card col-lg-4 m-auto border-0 ">
          <div className="row  p-1">
            <div className="col-12 d-flex justify-content-center align-self-center">
              <img src="/Tns-Logo.png" />
            </div>
            <form onSubmit={this.userLogin}>
              <div className="col-12 ">
                <div className="card mt-4 border-0 authCard p-4">
                  <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                    Location Portal Login
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="input-2">User Name</label>
                    <input
                      type="text"
                      value={this.state.email}
                      onChange={this.handleChangeEmail.bind(this)}
                      className="form-control"
                      id="input-2"
                      placeholder="Enter Email"
                    />
                    {this.state.InvalidEmail && (
                      <span style={{ color: "red" }}>Required*</span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="input-4">Password</label>
                    <input
                      type={this.state.PasswordType}
                      value={this.state.password}
                      onChange={this.handleChangePwd.bind(this)}
                      className="form-control"
                      id="input-4"
                      placeholder="Enter Password"
                    />
                    {!this.state.IsShowPassword && (
                      <span
                        className="passwordShow"
                        onClick={this.ShowPassword.bind(this)}
                      >
                        <FaEye className="inputIcon" role="button" />
                      </span>
                    )}
                    {this.state.IsShowPassword && (
                      <span
                        className="passwordHide"
                        onClick={this.HidePassword.bind(this)}
                      >
                        <FaEyeSlash className="inputIcon" role="button" />
                      </span>
                    )}
                    {this.state.InvalidPwd && (
                      <span style={{ color: "red" }}>Required*</span>
                    )}
                  </div>
                  <button
                    type="submit"
                    // onClick={this.userLogin}
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                  >
                    Login
                    {this.state.isLoader && (
                      <span className="loaderCustom">
                        <Loader
                          type="Oval"
                          color="#142454"
                          height={25}
                          width={25}
                          timeout={100000}
                        />
                      </span>
                    )}
                  </button>
                  {this.state.IsError && (
                    <span style={{ color: "red" }}>
                      Username or password is incorrect!
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <FooterLinks
          content={"Don't have an account?"}
          link={"/register"}
          linktext={"Sign Up Now!"}
        /> */}
      </div>
    );
  }
}

export default LoginLP;

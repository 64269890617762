import { ApiGetCall } from "./connector";

export async function GetProducts(subLinkId, bundleId) {
  const result = await ApiGetCall(
    `/api/Products?linkid=${parseInt(subLinkId)}&b=${parseInt(bundleId)}`
    // `/api/Products?linkid=${parseInt("18868")}&b=${parseInt("497")}`
  );
  try {
    if (result === undefined || result === "") {
      alert("Something went wrong");
    } else {
      return JSON.parse(result);
    }
  } catch (e) {
    console.log(e);
  }
  return result;
}

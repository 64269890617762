import React from "react";
import "./DataTable.css";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import PopupModal from "../Common/Popup";
import ExtendRental from "../rentalPage_popup/extendRental";
import PayBillModal from "../Bills/PayBillModal";

class dataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalCodes: [],
      ifBalZero: 0,
      fromDateValue: "",
      toDateValue: "",
      ifZero: "",
      Status: "",
      rentalCodeQr: 0,
      setLoader: true,
      setBackdrop: "true",
    };
  }

  callBackFun = (value) => {
    this.setState({ setBackdrop: value });
  };

  componentDidMount() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.setState({
      rentalCodeQr: parseFloat(params.rc),
      Status: params.st,
      fromDateValue: params.fd
        ? params.fd.split("-")[0] + params.fd.split("-")[1]
        : "18001",
      toDateValue: params.td
        ? params.td.split("-")[0] + params.td.split("-")[1]
        : "300001",
    });

    //  if (rentalCode) {

    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("RentalCode", "0");
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/Invoices", urlencoded).then((result) => {
      console.log("got it");
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        if (result != "No data found!") {
          const responseRs = JSON.parse(result);
          this.setState({ rentalCodes: responseRs[0] });
        }
        this.setState({ setLoader: false });
        $(".isGotData").text("1");
      }
    });

    // }
  }
  invoicesDetails = (title) => {
    var billId = $("#billId").attr("billid");
    var phoneNumber = $("#billId").attr("phonenumber");
    var Rcode = $("#billId").attr("rcode");
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("RentalCode", Rcode);
    urlencoded.append("BillId", billId);
    urlencoded.append("PhoneNumber", phoneNumber);
    urlencoded.append("Title", title);
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/InvoiceLogs", urlencoded).then((result) => {
      console.log("got it");
      if (result == undefined || result == "") {
        alert("Something went wrong");
      }
    });
  };
  render() {
    var LenRentalCode = Math.ceil(this.state.rentalCodes.length);
    var resultData = [];
    var convertedDate = "";
    var ifZero = "MarginTop";
    if (LenRentalCode > 0) {
      for (var i = 0; i < LenRentalCode; i++) {
        if (this.state.rentalCodes[i].BillMonth < 10) {
          convertedDate =
            this.state.rentalCodes[i].BillYear +
            "0" +
            this.state.rentalCodes[i].BillMonth;
        } else {
          convertedDate =
            this.state.rentalCodes[i].BillYear +
            "" +
            this.state.rentalCodes[i].BillMonth;
        }

        if (
          (this.state.rentalCodeQr == 0 ||
            this.state.rentalCodeQr == this.state.rentalCodes[i].RentalCode) &&
          convertedDate >= this.state.fromDateValue &&
          convertedDate <= this.state.toDateValue
          && this.state.ifBalZero !== this.state.rentalCodes[i].BalanceDue
          // &&
          // ((this.state.Status == "0" &&
          //   this.state.rentalCodes[i].BalanceDue <= 0) ||
          //   (this.state.Status == "1" &&
          //     this.state.rentalCodes[i].BalanceDue > 0) ||
          //   (this.state.Status == "-1" && "1" == "1") ||
          //   (this.state.Status == null && "1" == "1"))
        ) {
          ifZero = "false";
          resultData.push(
            <>
              <tr key={i}>
                <td className="TableDisplayBlock">{i}</td>
                <td className="removeFromMobile">
                  {this.state.rentalCodes[i].BillID}
                </td>
                <td>{this.state.rentalCodes[i].PhoneNumber}</td>
                <td className="removeFromMobile">
                  {this.state.rentalCodes[i].RentalCode}
                </td>
                <td>
                  <span className="d-none">{`${this.state.rentalCodes[i].BillID}`}</span>
                  {`${this.state.rentalCodes[i].BillMonth < 10
                    ? "0" + this.state.rentalCodes[i].BillMonth
                    : this.state.rentalCodes[i].BillMonth
                    }/${this.state.rentalCodes[i].BillYear}`}
                </td>
                <td>
                  {this.state.ifBalZero === this.state.rentalCodes[i].GrandTotal
                    ? `$0.00`
                    : `$${this.state.rentalCodes[i].GrandTotal.toFixed(2)}`}
                </td>
                <td className="removeFromMobile">
                  {this.state.ifBalZero === this.state.rentalCodes[i].BalanceDue
                    ? `$0.00`
                    : `$${this.state.rentalCodes[i].BalanceDue.toFixed(2)}`}
                </td>
                <td className="removeFromMobile">
                  {this.state.ifBalZero ===
                    this.state.rentalCodes[i].BalanceDue ? (
                    "Paid"
                  ) : (
                    <div style={{ color: "red" }}>Due</div>
                  )}
                </td>
                <td className="removeFromMobile">
                  {this.state.ifBalZero ===
                    this.state.rentalCodes[i].BalanceDue ? (
                    this.state.rentalCodes[i].CCTitle == "Discover" ? (
                      <div>
                        <img
                          src="/images/discoverIcon.png"
                          alt="discoverIcon"
                        />
                        {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                      </div>
                    ) : this.state.rentalCodes[i].CCTitle == "Visa" ? (
                      <div>
                        <img src="/images/visaIcon.png" alt="visaIcon" />
                        {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                      </div>
                    ) : (
                      <div>
                        <img
                          src="/images/masterCardIcon.png"
                          alt="mastercardIcon"
                        />
                        {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                      </div>
                    )
                  ) : (
                    <PopupModal
                      type="submit"
                      value="Submit"
                      className=""
                      class="payNowStyle p-0"
                      btnName="Pay Now"
                      size="ExtendRentalCardSize deleteCardDismissBtn"
                      backdrop={this.state.setBackdrop}
                    >
                      <PayBillModal
                        PB_data={[this.state.rentalCodes[i]]}
                        grandTotal={this.state.rentalCodes[
                          i
                        ].GrandTotal?.toFixed(2)}
                        setCallBackFun={this.callBackFun}
                      />
                    </PopupModal>
                    // <div className="payNowStye">Pay Now!</div>
                  )}
                  {/* {this.state.rentalCodes[i].CCTitle == "Discover" ? (
                    <div>
                      <img src="/images/discoverIcon.png" alt="discoverIcon" />
                      {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                    </div>
                  ) : this.state.rentalCodes[i].CCTitle == "Visa" ? (
                    <div>
                      <img src="/images/visaIcon.png" alt="visaIcon" />
                      {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                    </div>
                  ) : (
                    <div>
                      <img
                        src="/images/masterCardIcon.png"
                        alt="mastercardIcon"
                      />
                      {` ${this.state.rentalCodes[i].CCNumLastForDigits}`}
                    </div>
                  )} */}
                </td>
                <td className="paddingZero">
                  <a
                    title="View Invoice"
                    target="_blank"
                    id="billId"
                    rcode={this.state.rentalCodes[i].RentalCode}
                    billid={this.state.rentalCodes[i].BillID}
                    phonenumber={this.state.rentalCodes[i].PhoneNumber}
                    href={`https://www.talknsave.net/invoice/?billid=${this.state.rentalCodes[i].BillID}&code=${this.state.rentalCodes[i].PhoneNumber}&finalInvoice=1`}
                    onClick={() => this.invoicesDetails("View Invoice")}
                  >
                    <img src="/images/eyeIcon.png" alt="eyeIcon" />
                  </a>
                </td>
                <td className="paddingZero">
                  <a
                    title="Download Invoice"
                    id="billId2"
                    target="_blank"
                    href={`https://www.talknsave.net/invoice/?billid=${this.state.rentalCodes[i].BillID}&code=${this.state.rentalCodes[i].PhoneNumber}&pdf=1`}
                    onClick={() => this.invoicesDetails("Download Invoice")}
                  >
                    <img src="/images/pdfIcon.png" alt="pdfIcon" />
                  </a>
                </td>
              </tr>
            </>
          );
        }
      }
    }
    return (
      <>
        {this.state.setLoader ? (
          <>
            <span className="d-none isGotData">0</span>
            <div className="loader_positon text-center">
              <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
              <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
                Loading...
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={`datatableContainer ${ifZero}`}>
              <span className="d-none isGotData">0</span>
              <table id="paginationNumbers" className="table" width="100%">
                <thead className="cursorPointerThead">
                  <tr>
                    <th className="th-sm TableDisplayBlock"></th>
                    <th
                      className="th-sm removeFromMobile invoiceNumFilterp"
                      title="Sort by Invoice Number"
                    >
                      Invoice No.<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm" title="Sort by Phone Number">
                      Phone Number<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th
                      className="th-sm removeFromMobile"
                      title="Sort by Rental code"
                    >
                      Rental Code <i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th
                      className="th-sm invoiceDateFilter"
                      title="Sort by Invoice date"
                    >
                      Invoice Date<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm" title="Sort by Amount">
                      Total<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th
                      className="th-sm removeFromMobile"
                      title="Sort by Invoice Balance"
                    >
                      Balance<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm removeFromMobile">
                      Status<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th
                      className="th-sm removeFromMobile"
                      title="Sort by Payment Method"
                    >
                      Payment Method
                      <i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm"></th>
                    <th className="th-sm"></th>
                  </tr>
                </thead>
                <tbody>{resultData}</tbody>
                {/* <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Office</th>
                    <th>Age</th>
                    <th>Start date</th>
                    <th>Salary</th>
                  </tr>
                </tfoot> */}
              </table>
            </div>
          </>
        )}
      </>
    );
  }
}

export default dataTable;

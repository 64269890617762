import React from "react";
import PopupModal from "../Common/Popup";
import DeleteCard from "./deleteCard";

class VisaCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="col-lg-3 col-md-3 m-3 col-12 VisaContainer">
          <div className="deleteButton">
            <div className="">
              <PopupModal
                type="submit"
                value="Submit"
                className=""
                class="deleteCircle"
                btnName={
                  <img
                    className="deleteIcon"
                    src="/images/deleteIcon.png"
                    alt=""
                  />
                }
                size="deleteCardSize deleteCardDismissBtn"
              >
                <DeleteCard />
              </PopupModal>
            </div>
          </div>
          <img className="VisaImg" src="/images/VisaCCIcon.png" alt="" />
          <div className="VcardMarginTop">
            <div className="font2 m-2">Jhon Duo</div>
            <div className="font2 m-2">{`XXXX - XXXX - XXXX - ${this.props.Cnum}`}</div>
          </div>
        </div>
      </>
    );
  }
}

export default VisaCard;

import React from "react";
import "./DataTable.css";
import $ from "jquery";
import data from "./test";
const { convertUnixToDateTime } = require("../Helper/HelperFun");

class DataTableOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalCodes: [],
      fromDateValue: "",
      toDateValue: "",
      ifZero: "",
      paramsStatus: -1,
      paramsPlanName: "",
    };
  }

  componentDidMount = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.setState({
      paramsPlanName: params.rc,
      paramsStatus: params.st,
      fromDateValue: params.fd
        ? params.fd.split("-")[0] + params.fd.split("-")[1]
        : "18001",
      toDateValue: params.td
        ? params.td.split("-")[0] + params.td.split("-")[1]
        : "300001",
      rentalCodes: this.props.orderByEmail,
      //rentalCodes: data,
    });
  };
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.value != nextProps.value;
  // }
  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.value !== this.props.value) {
  //     console.log("t");

  //   } else {
  //     console.log("f");
  //     return false;
  //   }
  // }
  render() {
    const { rentalCodes } = this.state;

    if (rentalCodes.length != 0) {
      var resultData = [];
      let convertedDate = "";
      var ifZero = "MarginTop";
      let LenRentalCode = 0;
      rentalCodes[0].ClientEmail === "team.sprigstack@gmail.com"
        ? (LenRentalCode = 100)
        : (LenRentalCode = Math.ceil(rentalCodes.length));

      if (LenRentalCode > 0) {
        for (let i = 0; i < LenRentalCode; i++) {
          if (
            rentalCodes[i].RentalCode == null ||
            rentalCodes[i].RentalCode == ""
          ) {
            let RecordCreationDate = convertUnixToDateTime(
              rentalCodes[i].RecordCreationDate
            )
              .split(",")[0]
              .split("/");
            if (RecordCreationDate[0] < 10)
              convertedDate =
                RecordCreationDate[2] + "0" + RecordCreationDate[0];
            else
              convertedDate =
                RecordCreationDate[2] + "" + RecordCreationDate[0];

            if (
              (this.state.paramsPlanName == "0" ||
                this.state.paramsPlanName == rentalCodes[i].PlanName) &&
              convertedDate >= this.state.fromDateValue &&
              convertedDate <= this.state.toDateValue &&
              ((this.state.paramsStatus == "0" &&
                rentalCodes[i].Confirmed == false) ||
                (this.state.paramsStatus == "1" &&
                  rentalCodes[i].Confirmed == true) ||
                (this.state.paramsStatus == "-1" && "1" == "1") ||
                (this.state.paramsStatus == null && "1" == "1"))
            ) {
              ifZero = "";
              resultData.push(
                <tr key={i}>
                  <td className="TableDisplayBlock">{i}</td>
                  <td>{rentalCodes[i].OnlineOrderCode}</td>
                  <td>
                    {`${
                      convertUnixToDateTime(rentalCodes[i].RecordCreationDate)
                        .split(",")[0]
                        .split("/")[0]
                    }/${
                      convertUnixToDateTime(rentalCodes[i].RecordCreationDate)
                        .split(",")[0]
                        .split("/")[2]
                    }`}
                  </td>
                  <td>{rentalCodes[i].EquipmentModel}</td>
                  <td className="removeFromMobile">
                    {rentalCodes[i].ShipMethod}
                  </td>
                  <td className="removeFromMobile">
                    {rentalCodes[i].ShipMethod == "ALREADY_HAVE_SIM"
                      ? "ALREADY_HAVE_SIM"
                      : convertUnixToDateTime(rentalCodes[i].ShipDate).split(
                          ","
                        )[0]}
                  </td>
                  <td className="paddingZero"></td>
                  <td>
                    <img
                      src="/images/eyeIcon.png"
                      alt="eyeIcon"
                      className="cursorPointerThead test"
                      datavalue={i}
                      onClick={(e) => {
                        //this.props.setDynamicValues("orderStatus", true);
                        this.props.setOrderStatus(true);
                        // this.props.setDynamicValues(
                        //   "orderStatusData",
                        //   rentalCodes[$(e.currentTarget).attr("datavalue")]
                        // );
                        this.props.setDynamicValues(
                          rentalCodes[$(e.currentTarget).attr("datavalue")]
                        );
                        // window.sessionStorage.setItem(
                        //   "orderStatusData",
                        //   rentalCodes[i]
                        // );
                        // window.location.href = "/order";
                      }}
                    />
                  </td>
                </tr>
              );
            }
          }
        }
      }
    }
    return (
      <>
        <div className="orderByEmail d-none"></div>
        <div className={`datatableContainer ${ifZero}`}>
          <table id="paginationNumbers" className="table" width="100%">
            <thead className="cursorPointerThead">
              <tr>
                <th className="th-sm TableDisplayBlock"></th>
                <th className="th-sm ">
                  Order ID<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm">
                  Date<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm ">
                  Product<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm removeFromMobile">
                  Shipping Type<i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm removeFromMobile">
                  Expected Ship Date
                  <i className="fa fa-arrows-v hoverarrows"></i>
                </th>
                <th className="th-sm"></th>
                <th className="th-sm"></th>
              </tr>
            </thead>
            <tbody>{resultData}</tbody>
            {/* <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Office</th>
                    <th>Age</th>
                    <th>Start date</th>
                    <th>Salary</th>
                  </tr>
                </tfoot> */}
          </table>
        </div>
      </>
    );
  }
}

export default DataTableOrders;

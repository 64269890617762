import React from "react";
import $ from "jquery";
import CompleteRegister from "./completeregister";
import "./style.css";
import * as EmailValidator from 'email-validator';
import FooterLinks from "./footerlinks";
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRegisterNext: false,
      ValidEmailAddress: "",
    };
  }

  register = () => {
    if (
      EmailValidator.validate($("#emailAddess").val())
    ) {
      const emailAddress = $("#emailAddess").val();
      this.setState({
        IsRegisterNext: true,
        ValidEmailAddress: emailAddress,
      });
    } else {
      $("#emailAddess")
        .parent()
        .find(".errorText")
        .text("Enter a valid email address!");
    }
  };

  render() {
    return (
      <>
        {this.state.IsRegisterNext && (
          <CompleteRegister validEmail={this.state.ValidEmailAddress} />
        )}
        {!this.state.IsRegisterNext && (
          <div className="row m-0 loginPageBody">
            <div className="card col-lg-4 m-auto border-0 ">
              <div className="row  p-1">
                <div className="col-12 d-flex justify-content-center align-self-center">
                  <img src="/Tns-Logo.png" />
                </div>
                <div className="col-12 ">
                  <div className="card mt-4 border-0 authCard p-4">
                    <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                      Register
                    </div>
                    <p className="text-center">
                      You need to verify your email address before creating an
                      account. Please enter a valid email address.
                    </p>
                    <div className="form-group mb-3">
                      <label htmlFor="emailAddess">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailAddess"
                        placeholder="Enter Email"
                      />
                      <p className="errorText"></p>
                    </div>
                    <button
                      type="button"
                      onClick={this.register}
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <FooterLinks
              content={"Have an account?"}
              link={"/login"}
              linktext={"Sign In Now!"}
            />
          </div>
        )}
      </>
    );
  }
}

export default Register;

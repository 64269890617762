import React from "react";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import { List } from "reactstrap";

class allDataPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ADisCancelled: false, erMonth: [], errors: {} };
  }
  componentDidMount() {
    let SMSTopupGB = this.props.rentalData.SMSTopupGB;
    let SMSTopupPrice = this.props.rentalData.SMSTopupPrice;
    if (SMSTopupGB != undefined && SMSTopupGB != null && SMSTopupGB != "") {
      this.setState({ DataGb: SMSTopupGB });
    } else {
      this.setState({ DataGb: "30" });
    }
    if (SMSTopupPrice) {
      this.setState({ DataPrice: SMSTopupPrice });
    } else {
      this.setState({ DataPrice: 10 });
    }
  }
  ERconfirm2 = () => {
    this.setState({ ADisCancelled: true });
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("PhoneNumber", this.props.rentalData.PhoneNumber);
    urlencoded.append("RentalCode", this.props.RcoDe);
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/AddTopUp", urlencoded).then((result) => {
      console.log(result);
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs);
      }
    });
  };

  ERDismiss = () => {
    $(".deleteCardDismissBtn").find("#closebtn2").click();
  };

  render() {
    return (
      <>
        {!this.state.ADisCancelled && (
          <>
            <div className="ERfont1">Instant Data Top-Up</div>
            <div className="ERfont2 p-3">
              Get an additional {this.state.DataGb}GB of data added on to your
              rental for ${this.state.DataPrice}.
            </div>

            {/* <div className="ERfont4 pt-3">You’ll be charged</div> */}
            {/* <div className="ERfont4 pt-3">$25.00</div> */}
            <div className="pt-4 pb-3">
              <button className="ERConBtn" onClick={this.ERconfirm2}>
                Confirm
              </button>
            </div>
          </>
        )}
        {this.state.ADisCancelled && (
          <>
            <div className="ERfont1">Thank you!</div>
            <div className="ERfont2 p-3">
              Your data top-up has been requested. Please check your mobile
              phone for an SMS confirmation.
            </div>

            <div className="pt-4 pb-3">
              <button className="ERConBtn" onClick={this.ERDismiss}>
                Confirm
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}
export default allDataPopup;

import React from "react";
import { Button } from "react-bootstrap";

export default function POPopup(props) {
  return (
    <React.Fragment>
      <div className="p-3">
        {props?.successData?.loader ? (
          <>
            <div className="ro-font5 c142454 text-center mb-4">
              Please wait!
            </div>
            <div className="row">
              <div className="col-2 m-auto">
                <img
                  className="ms-2"
                  src="/svgs/loader.svg"
                  alt="eyeIcon"
                  style={{ width: "70%" }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="ro-font5 c142454 text-center mb-4">
              {props?.successData?.result?.Status == "Success"
                ? "Order Placed!"
                : "Something went wrong!"}
            </div>
            {props?.successData?.result?.Status == "Success" ? (
              <>
                <div className="row mb-3">
                  <div className="col-4 ro-font4 c142454">Order Number :</div>
                  <div className="col-8 ro-font4 test">
                    {props?.successData?.result?.Data}
                  </div>
                </div>
                {/* <div className="row mb-4">
                                          <div className="col-4 ro-font4 pe-0 c142454">
                                            Phone Assigned :
                                          </div>
                                          <div className="col-8 ro-font4">
                                            {props?.successData?.Data?.PhoneAssigned}
                                          </div>
                                        </div> */}
              </>
            ) : (
              <>
                <div className="ro-font3 mb-3 text-center">
                  {props?.successData?.result?.Data}
                </div>
              </>
            )}
            <div className="row">
              <div className="col-12">
                <Button
                  variant="btn ro-button py-2 w-100"
                  onClick={() => {
                    props?.successData?.result?.Status == "Success"
                      ? window.location.reload()
                      : props.setShow(false);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}

import React from "react";
import Button from "react-bootstrap/Button";
export default function AssignPopup(props) {
  return (
    <React.Fragment>
      <div className="p-3">
        {props?.successData?.Status == "Success" ? (
          <>
            <div className="ro-font5 c142454 text-center mb-4">
              Number Assigned!
            </div>
            <div className="ro-font3 mb-3 text-center">
              Your request has been received. Please allow 24 hours for the
              system to reflect the updated information.
            </div>
            {/* <div className="row mb-3">
              <div className="col-4 ro-font4 c142454">KNT Assigned :</div>
              <div className="col-8 ro-font4">
                {props?.successData?.Data?.KNTAssigned}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4 ro-font4 pe-0 c142454">
                Phone Assigned :
              </div>
              <div className="col-8 ro-font4">
                {props?.successData?.Data?.PhoneAssigned}
              </div>
            </div> */}
          </>
        ) : (
          <>
            <div className="ro-font5 text-danger text-center mb-3">Error!</div>
            <div className="ro-font3 mb-3 text-center">
              {props?.successData?.Data}
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12">
            <Button
              variant="btn ro-button py-2 w-100"
              onClick={() => props.setShow(false)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

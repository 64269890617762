import React from "react";
import "./ChnagePlan.css";
import PopupModal from "./Popup";
import ChangePlanDetails from "./changeplanDetails";
import $ from "jquery";
class ChangePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {!this.props.popupSize && (
          <div>
            <div className="details">
              <div className=" text-center font1">Change Plan</div>
            </div>
            <div className="details">
              <div className="  font2">
                You need to verify your email address before creating an
                account. Please enter a valid email address.
              </div>
            </div>
            <div className="">
              <div className="font3">Plan Type</div>
              <div className="">
                <select
                  className="form-select statusBox"
                  aria-label="Default select example"
                  id="select"
                >
                  <option className="PlaceHolder">
                    Short Trip Monthly Rates
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Due">Due</option>
                </select>
              </div>
            </div>
            <div className="row statusPlan ">
              <div className="col-lg-6 col-md-6 p-2  box1">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      defaultChecked
                      type="radio"
                      className="form-check-input"
                      name="optradio"
                    />
                    Sim Plan
                    <div className="font4">Data - 25GB</div>
                    <div className="font4">Local Talk & Text - Unlimited*</div>
                    <div className="font4">Intl' Calling - Unlimited**</div>
                    <div className="font4">$49.99 /mo</div>
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 p-2 box2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="optradio"
                    />
                    Kid Plan
                    <div className="font4">Data - Closed</div>
                    <div className="font4">Local Talk & Text - Unlimited*</div>
                    <div className="font4">
                      Intl' Calling - $0.17 per minute**
                    </div>
                    <div className="font4">$14.99 /mo</div>
                  </label>
                </div>
              </div>
            </div>
            <div className="font5">
              *Unlimited is a fair usage of 3000 minutes.{" "}
            </div>
            <div className="row mobileCenter">
              <div className="col-lg-6 col-md-6 ">
                <button
                  className="dismissbtn"
                  onClick={this.props.dismissClick}
                >
                  Dismiss
                </button>
              </div>
              <div className="col-lg-6 col-md-6 ">
                <button className="changebtn" onClick={this.props.confirmClick}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {this.props.popupSize && (
          <div>
            <div className="details">
              <div className=" text-center font1">Plan Changed!</div>
            </div>
            <div className="details">
              <div className="  font2">
                At eget urna faucibus tincidunt nisl ipsum et. Duis donec aenean
                etiam viverra massa dictum purus potenti facilisis. Sollicitudin
                sed sapien.
              </div>
            </div>

            <div className="row mobileCenter">
              <div className="col-lg-12 col-md-12 text-center">
                <button
                  className="dismissbtnDetails"
                  onClick={this.props.dismissClick}
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <div className="row">
          <div className="col-lg">
            <button type="submit" className="btn btn applybtn2">
              <div className="applyFont">Close</div>
            </button>
          </div>
        </div> */}
      </>
    );
  }
}
export default ChangePlan;

import React from "react";
import PopupModal from "../Common/Popup";
import DeleteCard from "./deleteCard";
import AddNewCard from "./addNewCard";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let headerImage = "/images/phonepayIcon.png";
    if (
      this.props.eModel != null &&
      this.props.eModel != undefined &&
      this.props.eModel.includes("SIM")
    ) {
      headerImage = "/images/simpayIcon.png";
    }
    return (
      <>
        <div
          className={` col-lg-4 col-md-4 m-3 col-12  ${
            this.props.title.toLowerCase().trim() == "mastercard"
              ? "MasterContainer"
              : this.props.title.toLowerCase().trim() == "visa"
              ? "VisaContainer"
              : "DiscoverContainer"
          }`}
        >
          <div className="deleteButton">
            {/* <PopupModal
              type="submit"
              value="Submit"
              className=""
              class="deleteCircle"
              btnName={
                <img className="deleteIcon" src="/images/editIcon.png" alt="" />
              }
              size="deleteCardSize deleteCardDismissBtn"
            >
              <DeleteCard cnumber={this.props.Cnum} />
            </PopupModal> */}
            <PopupModal
              type="submit"
              value="Submit"
              className=""
              class="deleteCircle"
              btnName={
                <img className="deleteIcon" src="/images/editIcon.png" alt="" />
              }
              size="addNewCardSize deleteCardDismissBtn"
            >
              <AddNewCard
                cnumber={this.props.pnumbers}
                RcoDe={this.props.RCODE}
                title="Update Card"
              />
            </PopupModal>
          </div>
          {this.props.title.toLowerCase().trim() == "mastercard" ? (
            <>
              <img
                className="MasterImg"
                src="/images/MasterCardCCIcon.png"
                alt=""
              />
            </>
          ) : this.props.title.toLowerCase().trim() == "visa" ? (
            <>
              <img className="VisaImg" src="/images/VisaCCIcon.png" alt="" />
            </>
          ) : (
            <>
              <img
                className="DicoverImg"
                src="/images/DiscoverCardIcon.png"
                alt=""
              />
            </>
          )}

          <div
            className={
              this.props.title.toLowerCase().trim() == "mastercard"
                ? "McardMarginTop"
                : this.props.title.toLowerCase().trim() == "visa"
                ? "VcardMarginTop"
                : "DcardMarginTop"
            }
          >
            <div className="font2Card m-2">{this.props.CName}</div>
            <div className="font2Card m-2">{`XXXX - XXXX - XXXX - ${this.props.Cnum}`}</div>
          </div>
          <div className="row imgmarLeft">
            <div className="col-lg-4 col-4 payImgBox">
              <img src={`${headerImage}`} />
            </div>
            <div className="col-lg-8 col-8">
              <div className="row">
                <div className="col-lg-12 font2Card  font2M">
                  {this.props.pnumbers}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 font2Card font2M fontspacing">
                  {this.props.eModel}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Card;

import React, { useLayoutEffect, useState } from "react";
import { ApiPostCall } from "../Functions/connector";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import GetHeightWidth from "../Helper/GetHeightWidth";

export default function Location() {
  const screenWidth = GetHeightWidth();
  const UserData = window.localStorage.getItem("userData");

  const Token = window.localStorage.getItem("Token");
  const { ParentLink, ShippingId, BaseCode } = JSON.parse(UserData)[0];
  const [ParentLinkData, setParentLinkData] = useState([]);
  const [logisticsSummaryList, setLogisticsSummaryList] = useState([]);
  const [Loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    LocationData();
  }, []);

  const LocationData = async () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("ParentLink", ParentLink);
    urlencoded.append("Token", Token);
    await ApiPostCall(
      "/RouterParserLocationPortal/GetBundlesOrSubLinkList",
      urlencoded
    ).then((result) => {
      try {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          if (responseRs?.Data != "No data Found") {
            setParentLinkData(JSON.parse(responseRs.Data)[0]);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    });
    // var dataLength = false;
    // urlencoded.append("ShipId", ShippingId);
    // urlencoded.append("BaseCode", BaseCode);
    // urlencoded.append("PageName", "LogisticsSummary");
    // await ApiPostCall(
    //   "/RouterParserLocationPortal/GetRecentAndLogisticsData",
    //   urlencoded
    // ).then((result) => {
    //   try {
    //     if (result === undefined || result === "") {
    //       alert("Something went wrong");
    //     } else {
    //       const responseRs = JSON.parse(result);
    //       if (responseRs?.Data != "Data not found") {
    //         dataLength = true;
    //         console.log(JSON.parse(responseRs?.Data)[0]);
    //         var result =
    //           JSON.parse(responseRs?.Data)[0] == undefined
    //             ? []
    //             : JSON.parse(responseRs?.Data)[0];
    //         setLogisticsSummaryList(result);
    //       }
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     document.querySelector(".isGotData").textContent = "1";
    //     setTimeout(() => {
    //       $("#paginationNumbers_filter label")
    //         .contents()
    //         .filter(function () {
    //           return this.nodeType === Node.TEXT_NODE;
    //         })
    //         .remove();
    //       if (screenWidth.width > 720) {
    //         $(
    //           ".LocationTable #paginationNumbers_wrapper .row:nth-child(1) .col-sm-6:nth-child(2)"
    //         ).css({ top: dataLength ? "-98px" : "-118px", left: "15px" });
    //       }
    //     }, 100);
    //   }
    // });
    // setLoader(false);
  };

  const PlaceOrder = (e) => {
    e.preventDefault();
    var parentLink = e.target.parentLink.value;
    if (parentLink != "") navigate(`/place-order/${parentLink}/${ShippingId}`);
    // window.location.href = `/place-order/${parentLink}/${ShippingId}`;
  };
  return (
    <React.Fragment>
      {Loader ? (
        <>
          <span className="d-none isGotData">0</span>
          <div className="loader_positon text-center">
            <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
            <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
              Loading...
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="d-none isGotData">0</span>
          <div className="pageMargin">
            <div className="row">
              {/* <div className="col-md-5 col-12">
                <div className="recentOrderContainer p-4">
                  <div className="row">
                    <div className="col-2 ">
                      <div className="boxCircle">
                        <img src="./images/search.svg" alt="" />
                      </div>
                    </div>
                    <div className="col-10 ro-font1 my-1 d95b08 align-self-center">
                      Search
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-12 ro-font1 my-1 c142454">
                      <div className="row">
                        <div className="col-8">
                          <div className="form-group">
                            <label htmlFor="">Equipment Type</label>
                            <div className="row my-4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-12 mt-md-0 mt-4">
                <div className="recentOrderContainer p-4">
                  <div className="row">
                    <div className="col-cus-2">
                      <div className="boxCircle">
                        <img src="./images/LocationPortal/box.svg" alt="" />
                      </div>
                    </div>
                    <div className="col-10 ro-font1 my-1 d95b08 align-self-center">
                      Place an order
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-12 ro-font1 my-1 c142454">
                      <form onSubmit={(e) => PlaceOrder(e)}>
                        <div className="row">
                          <div className="col-9">
                            <div className="form-group">
                              <label htmlFor="">Select a plan</label>
                              <select
                                className="form-select select mb-0"
                                name="parentLink"
                                id=""
                              >
                                <option value="">Select a plan</option>
                                {ParentLinkData?.map((data, i) => {
                                  return (
                                    <>
                                      <option value={data?.SubLinkId}>
                                        {data?.GroupName}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div
                            className="col-3"
                            style={{ alignSelf: "flex-end" }}
                          >
                            <button
                              type="submit"
                              className="btn ro-button2 w-100"
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className={`row p-3 position-relative ${
                logisticsSummaryList?.length == 0 && "MarginTop"
              }`}
            >
              <div className="col-md-12 col-12 px-0 LocationTable">
                <table id="paginationNumbers" className="table" width="100%">
                  <thead>
                    <tr>
                      <th className="th-sm TableDisplayBlock"></th>
                      <th className="th-sm ">
                        Equipment
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                      <th className="th-sm ">
                        Phones In
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                      <th className="th-sm ">
                        Pending Returns
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                      <th className="th-sm">
                        Pending Orders
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                      <th className="th-sm ">
                        Available
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                      <th className="th-sm removeFromMobile">
                        All Orders
                        <i className="fa fa-arrows-v hoverarrows"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logisticsSummaryList?.map((data, i) => {
                      return (
                        <>
                          <tr>
                            <td className="TableDisplayBlock">{i}</td>
                            <td>{data?.EquipmentType}</td>
                            <td className="">{data?.PhonesIn}</td>
                            <td>{data?.PendingOrders}</td>
                            <td>{data?.PendingReturns}</td>
                            <td>{data?.Available}</td>
                            <td className="removeFromMobile">
                              {data?.AllOrders}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </>
      )}
    </React.Fragment>
  );
}

import React, { useState, useLayoutEffect } from "react";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PayBillYupValidation } from "../Yup Validation/YupValidations";
import { getAfterTenYears } from "../Helper/HelperFun";
import { ApiPostCall } from "../Functions/connector";
import Loader from "react-loader-spinner";

export default function PayBillModal(props) {
  const [success, setSuccess] = useState({
    change: false,
    payBillData: [],
    loader: false,
    waitingMsg: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PayBillYupValidation),
  });
  useLayoutEffect(() => {
    $("#credit-card").on("keypress change", function () {
      $(this).val(function (index, value) {
        return value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
      });
    });
    // console.log(props.PB_data);
  }, []);

  const PayBillData = (data) => {
    // console.log(data);
    if (props.PB_data?.length == 0) {
      setSuccess({
        ...success,
        waitingMsg: "No record found!",
      });
      return;
    }
    props.setCallBackFun("static");
    $("#PayBillModalBtn").attr("disabled", "true");
    $("#closebtn2").attr("disabled", "true");
    setSuccess({
      ...success,
      loader: true,
      waitingMsg: "Please wait while the transaction is complete.",
    });
    const token = window.localStorage.getItem("Token");
    let isBillPage = false;
    var urlencoded = new URLSearchParams();
    if (props.ValidRC) {
      urlencoded.append("Token", "PayBill");
      isBillPage = true;
    } else {
      urlencoded.append("Token", token);
    }

    urlencoded.append("cardName", data.cardName);
    urlencoded.append("cardNumber", data.cardNum);
    urlencoded.append("cardType", data.cardType);
    urlencoded.append("cardExpireMonth", data.cardMonth);
    urlencoded.append("cardExpireYear", data.cardYear);
    urlencoded.append("isDefaultCard", data.isDefaultCard);
    urlencoded.append("PayBillData", JSON.stringify(props.PB_data));

    ApiPostCall("/RouterParser/PayBillDev", urlencoded).then((result) => {
      try {
        // console.log(result);
        let data = JSON.parse(result);
        setSuccess({
          ...success,
          change: true,
          payBillData: data,
          waitingMsg: "",
          loader: false,
        });
        props.setCallBackFun("true");
        $("#closebtn2").removeAttr("disabled");
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    });
  };
  return (
    <>
      {props.Status ? (
        <>
          <div className="row p-1 DCfont1 text-danger justify-content-center">
            Invalid account!
          </div>
        </>
      ) : !success.change ? (
        <>
          <div className="row p-1 DCfont1 justify-content-center">
            Make a Payment
          </div>
          <div className="row p-1 justify-content-center text-center h6">
            Add a new payment method to pay your invoice(s).
          </div>
          <div className="row my-2">
            <div className="col-lg-4 col-5 c142454 PB_Font1">Card Details</div>
          </div>
          <form onSubmit={handleSubmit(PayBillData)}>
            <div className="row">
              <div className="col-lg ">
                <div className="Font">Credit Card Type</div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <select
                      className="form-select selectbtn w-100"
                      aria-label="Default select example"
                      id="cardType"
                      {...register("cardType")}
                    >
                      <option value="Visa" defaultValue className="PlaceHolder">
                        Visa
                      </option>
                      <option value="MasterCard">Mastercard</option>
                      <option value="Discover">Discover</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="Font">Card Number</div>
                <input
                  type="text"
                  name="cardNum"
                  className="form-control selectbtn w-100"
                  placeholder="XXXX - XXXX - XXXX - XXXX"
                  id="credit-card"
                  maxLength="19"
                  {...register("cardNum")}
                />
                <span className="text-danger">
                  {errors.cardNum?.message
                    .split(/[\s,\?\,\.!]+/)
                    .some((f) => f === "`number`")
                    ? "Please enter only digits."
                    : errors.cardNum?.message}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="CardName" className="form-label Font">
                  Name on Card
                </label>
                <input
                  type="text"
                  name="cardName"
                  className="form-control p-2"
                  id="CardName"
                  placeholder="Enter your name on card"
                  {...register("cardName")}
                />
                <span className="text-danger">{errors.cardName?.message}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg ">
                <div className="Font">Expiration Date</div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-6">
                    <select
                      className="form-select selectbtn w-100"
                      aria-label="Default select example"
                      id="month"
                      {...register("cardMonth")}
                    >
                      <option defaultValue className="PlaceHolder" value="">
                        Month
                      </option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <span className="text-danger">
                      {errors.cardMonth?.message}
                    </span>
                  </div>

                  <div className="col-lg-6 col-md-6 col-6">
                    <select
                      className="form-select selectbtn w-100"
                      aria-label="Default select example"
                      id="year"
                      {...register("cardYear")}
                    >
                      <option defaultValue className="PlaceHolder" value="">
                        Year
                      </option>
                      {getAfterTenYears().map((yr, i) => (
                        <>
                          <option key={i} value={yr}>
                            {yr}
                          </option>
                        </>
                      ))}
                    </select>
                    <span className="text-danger">
                      {errors.cardYear?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12">
                    <input
                      type="checkbox"
                      className=""
                      aria-label="Default  example"
                      id="isDefaultCard"
                      {...register("isDefaultCard")}
                    ></input>

                    <label htmlFor="isDefaultCard" className="p-2">
                      Make this card default?
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row invoice-loading-msg">
              {props.ValidRC ? (
                <div className="col-12 text-center h3 p-2 blink_me">
                  Loading outstanding invoices...
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* <div className="row">
              <div className="col-lg-12">
                <label htmlFor="CVV" className="form-label Font">
                  CVV
                </label>
                <input
                  type="text"
                  className="form-control p-2"
                  id="CVV"
                  placeholder="Enter your CVV number"
                  {...register("cardCVV")}
                />
                <span className="text-danger">
                  {errors.cardCVV?.message
                    .split(/[\s,\?\,\.!]+/)
                    .some((f) => f === "`number`")
                    ? "Please enter only digits."
                    : errors.cardCVV?.message}
                </span>
              </div>
            </div> */}
            {props.PB_data?.map((ListData, i) => {
              return (
                <>
                  <div className="row mt-4 mb-2 px-3" key={i}>
                    <div className="col-lg-6 col-9 h5">
                      Invoice #{ListData.BillID}
                    </div>
                    <div className="col-lg-6 col-3 h5 PB_flexEnd">
                      ${ListData.BalanceDue.toFixed(2)}
                      <img
                        className="ms-2 cursor-pointer"
                        src="/images/PB_eyeIcon.svg"
                        alt=""
                        onClick={() => {
                          window.open(
                            `https://www.talknsave.net/invoice/?billid=${ListData.BillID}&code=${ListData.PhoneNumber}&finalInvoice=1`,
                            "_blank"
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })}

            <div className="row my-2 px-3">
              <div className="col-lg-6 col-6 PB_Font2 c142454">Total</div>
              <div className="col-lg-6 col-6 PB_Font2 c142454 PB_flexEnd">
                $ {props.grandTotal}
              </div>
            </div>
            <span className="text-danger my-2">{success.waitingMsg}</span>
            <div className="row pt-4 pb-3 px-3">
              <button
                type="submit"
                value="Submit"
                className="form-control ERConBtn"
                id="PayBillModalBtn"
              >
                Confirm
                {success.loader && (
                  <span className="loaderCustom">
                    <Loader
                      type="Oval"
                      color="#142454"
                      height={25}
                      width={25}
                      timeout={100000}
                    />
                  </span>
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="row p-1  DCfont1 justify-content-center">
            Transaction Details
          </div>

          {(success.payBillData[0].Status == "Error" &&
            success.payBillData[0].Message ==
            "The credit card number is invalid.") ||
            (success.payBillData[0].Status == "CC declined" &&
              success.payBillData[0].Message ==
              "CC declined (Please contact TalknSave)") ? (
            <>
              <div className="row mt-4 mb-2 px-3">
                <div
                  className="col-lg-12 col-7 h5 text-center"
                  style={{
                    color: "red",
                  }}
                >
                  {success.payBillData[0].Message}
                </div>
              </div>
            </>
          ) : (
            <>
              <ul className="my-2">
                {success.payBillData?.map((data, i) => {
                  return (
                    <>
                      <li
                        className="h6"
                        style={{
                          color: data.Status == "Success" ? "green" : "red",
                          listStyle: "outside",
                        }}
                      >
                        {`${data.Message.replace(
                          new RegExp("." + "$"),
                          ""
                        )} for Invoice # ${data.BillID} ${data?.LineStatusCode != "" ? <>and {data?.LineStatusCode}</> : ""}`}
                      </li>
                      {/* <div className="row mt-4 mb-2 px-3" key={i}>
                    <div className="col-lg-1 h6">{i + 1}.</div>
                    <div
                      className="col-lg-11 col-7 h6"
                      style={{
                        color: data.Status == "Success" ? "green" : "red",
                      }}
                    >{`${data.Message.replace(
                      new RegExp("." + "$"),
                      ""
                    )} for Invoice # ${data.BillID}`}</div>
                  </div> */}
                      {/* <div className="row mt-3 mb-5 p-1 justify-content-center text-center h4">
                  {success.message}
                  {success.status == "Error" &&
                    success.message ==
                      "The credit card number is invalid" && (
                      <span
                        className="my-3 h6 text-start ms-0 ms-lg-4"
                        style={{ color: "red" }}
                      >
                        Note: (Please contact TalknSave)
                      </span>
                    )}
                </div> */}
                    </>
                  );
                })}
              </ul>
              {props.ValidRC ? (
                <div className="row">
                  <div className="col-12 text-center themeTxtColor">
                    <a href="/login" className="inheritColor">
                      Log In!
                    </a>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

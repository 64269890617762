import React from "react";
import $ from "jquery";
import FooterLinks from "./footerlinks";

class MessageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  register = () => {
    window.location.href = "/login";
  };

  render() {
    return (
      <div className="row m-0 loginPageBody">
        <div className="card col-lg-4 m-auto border-0 ">
          <div className="row  p-1">
            <div className="col-12 d-flex justify-content-center align-self-center">
              <img src="/Tns-Logo.png" />
            </div>
            <div className="col-12 ">
              <div className="card mt-4 border-0 authCard p-4">
                <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                  {this.props.title}
                </div>
                <div className="text-center">{this.props.content}</div>

                <button
                  type="button"
                  onClick={this.register}
                  className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                >
                  {this.props.ButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.IsFooter && (
          <>
            <FooterLinks
              content={this.props.footerText}
              link={this.props.footerLink}
              linktext={this.props.footerLinkText}
            />
          </>
        )}
      </div>
    );
  }
}
export default MessageContainer;

import React from "react";
import { useLayoutEffect } from "react";

export default function () {
  useLayoutEffect(() => {
    const loggedInUser = window.localStorage.getItem("user");
    localStorage.removeItem("user");
    localStorage.removeItem("Token");
    localStorage.removeItem("InvoicesData");
    localStorage.clear();
    window.location.href =
      loggedInUser == "Location Portal" ? "/location-portal-login" : "/login";
  });
  return <></>;
}

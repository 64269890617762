import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import RentalCard from "./rentalCard";
import $ from "jquery";

class RentalCarousel_New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentals: [],
    };
  }

  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      var foundUsersArr = foundUser[0];
      var listOfRentals = [];
      for (var i = 0; i < foundUsersArr.length; i++) {
        // this.setState({ rentals: {...this.stste.rentals,rentals: foundUsersArr[i].Status == "Active" ? listOfRentals.push(foundUsersArr[i] : } });
        if (foundUsersArr[i].Status == "Active") {
          listOfRentals.push(foundUsersArr[i]);
          this.setState({ rentals: listOfRentals });
        }
      }
      for (var i = 0; i < foundUsersArr.length; i++) {
        if (foundUsersArr[i].Status != "Active") {
          listOfRentals.push(foundUsersArr[i]);
          this.setState({ rentals: listOfRentals });
        }
      }
    }

    this.setState({ sizes: 2 });
    var screenWidth = window.screen.width;
    if (screenWidth < 720) {
      this.setState({ sizes: 1 });
    }

    // $(window).resize(function () {
    //   var width = $(window).width();
    //   if (width < 720) {
    //     window.location.href = "/dashboard";
    //   }
    // });
  }
  myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <img src="./images/iconArrowLeft.png" alt="" />
      ) : (
        <img src="./images/iconArrowRight.png" alt="" />
      );

    return (
      <>
        <button
          className={` NextPrevBtn ${isEdge ? "cursor" : ""}`}
          onClick={onClick}
          disabled={isEdge}
        >
          {pointer}
        </button>
      </>
    );
  }

  render() {
    const { rentals, sizes } = this.state;
    return (
      <>
        {rentals?.length > 0 &&
          <div className="mt-2">
            <div className="cardHeader col-lg-12 ">Your Rentals</div>
            <Carousel
              itemsToScroll={sizes}
              itemsToShow={sizes}
              showEmptySlots
              enableAutoPlay
              autoPlaySpeed={10000}
              itemPadding={[10, 10]}
              renderArrow={this.myArrow}
            // easing="cubic-bezier(1,.15,.55,1.54)"
            // tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
            // transitionMs={700}
            >
              {rentals.map((rentals, i) => (
                <div style={{ width: "100%" }} key={i} id={i}>
                  <RentalCard
                    statusClass={i % 2 == 0 ? "statusActive" : "statusPaid"}
                    rental={rentals}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        }

      </>
    );
  }
}

export default RentalCarousel_New;

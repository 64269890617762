import React, { useLayoutEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ApiGetCall } from "../Functions/connector";
import { convertUnixToDateTime } from "../Helper/HelperFun";

export default function WrongDialledCall() {
  const [mobileFilter, setMobileFilter] = useState("filterContainerMobile");
  const [WrongDialledCallData, setWrongDialledCallData] = useState({
    MainData: [],
    filterData: [],
  });
  const [Loader, setLoader] = useState(true);
  const RC = new URLSearchParams(useLocation().search).get("rc");
  useLayoutEffect(() => {
    if (RC != "" && RC != null) {
      ApiGetCall(`/RouterParser/WorngDialedCall?RC=${RC}`).then((result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = JSON.parse(result);
            if (responseRs.Status == "Success") {
              var result =
                JSON.parse(responseRs?.Data)[0] == undefined
                  ? []
                  : JSON.parse(responseRs?.Data)[0];
              setWrongDialledCallData({
                ...WrongDialledCallData,
                MainData: result,
                filterData: result,
              });
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          document.querySelector(".isGotData").textContent = "1";
          setLoader(false);
        }
      });
    } else {
      document.querySelector(".isGotData").textContent = "1";
      setLoader(false);
    }
  }, []);

  const FilterData = (e) => {
    e.preventDefault();
    if (e.target.month.value == "0") {
      setWrongDialledCallData({
        ...WrongDialledCallData,
        filterData: WrongDialledCallData.MainData,
      });
    } else {
      setWrongDialledCallData({
        ...WrongDialledCallData,
        filterData: WrongDialledCallData.MainData.filter(
          (data) =>
            convertUnixToDateTime(data?.CallDate).split(",")[0].split("/")[1] ==
            e.target.month.value
        ),
      });
    }
  };

  return (
    <React.Fragment>
      {Loader ? (
        <>
          <span className="d-none isGotData">0</span>
          <div className="loader_positon text-center">
            <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
            <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
              Loading...
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="d-none isGotData">0</span>
          <div className=" row  ">
            <div className=" Mobile">
              <div
                className={`mobile topHeader mleft  ${
                  mobileFilter == "" ? "d-none" : ""
                }`}
              >
                Invoice
                <i
                  className="fa fa-solid fa-filter pullRight mRight pt-2"
                  onClick={() =>
                    setMobileFilter(
                      mobileFilter == "" ? "filterContainerMobile" : ""
                    )
                  }
                ></i>
              </div>
            </div>
            <form onSubmit={FilterData}>
              <div className=" col-lg-12 col-md-12 col-12">
                <div
                  className={`row filterContainer  ${mobileFilter}
            `}
                  style={{ justifyContent: "start" }}
                >
                  <div className="col-10 mobile">
                    <div className="filterInvoice mobile">Filter Invoice</div>
                  </div>
                  <div
                    className="col-2 closebtn mobile"
                    onClick={() =>
                      setMobileFilter(
                        mobileFilter == "" ? "filterContainerMobile" : ""
                      )
                    }
                  >
                    x
                  </div>

                  <div className="col-lg-3 col-md col-12">
                    <div className="fromDate">Month</div>
                    <div className="" title="Select start date">
                      <select
                        className="form-select selectbtn w-100"
                        aria-label="Default select example"
                        id="month"
                        name="month"
                      >
                        <option defaultValue className="PlaceHolder" value="0">
                          Months
                        </option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md col-12">
                    <button
                      type="submit"
                      value="Submit"
                      className="btn btn applybtn w-100"
                    >
                      <div className="applyFont" title="Apply Filters">
                        Apply Filters
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            className={`row p-3 ${
              WrongDialledCallData?.MainData?.length == 0 && "MarginTop"
            }`}
          >
            <div className="col-md-6 col-12">
              <table
                id="paginationNumbers"
                className="table wrongDialedCallTable"
                width="100%"
              >
                <thead>
                  <tr>
                    <th className="th-sm TableDisplayBlock"></th>
                    <th className="th-sm removeFromMobile">
                      Call Date
                      <i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm removeFromMobile">
                      Call Time
                      <i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm ">
                      Rental Code<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm">
                      Caller Phone<i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                    <th className="th-sm ">
                      Number Dialed
                      <i className="fa fa-arrows-v hoverarrows"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {WrongDialledCallData?.filterData?.map((data, i) => {
                    return (
                      <>
                        <tr key={data?.CallerPhone}>
                          <td className="TableDisplayBlock">{i}</td>
                          <td className="removeFromMobile">
                            {
                              convertUnixToDateTime(data?.CallDate).split(
                                ","
                              )[0]
                            }
                          </td>
                          <td className="removeFromMobile">
                            {
                              convertUnixToDateTime(data?.CallDate).split(
                                ","
                              )[1]
                            }
                          </td>
                          <td>{data?.RentalCode}</td>
                          <td>{data?.CallerPhone}</td>
                          <td>{data?.NumberDialed}</td>
                        </tr>
                      </>
                    );
                  })}
                  {WrongDialledCallData?.filterData?.length == 0 &&
                    WrongDialledCallData.MainData.length != 0 && (
                      <>
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan="5"
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>
                        </tr>
                      </>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

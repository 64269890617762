import React, { useLayoutEffect, useState, useEffect } from "react";

export default function MultipleDate(props) {
  const [inputValues, setInputValues] = useState([]);
  useLayoutEffect(() => {
    var ListData = [];
    if (props?.BeginEndDate?.MultipleDates) {
      ListData.push({
        id: 0,
        begin_Date: props.BeginEndDate?.StartDateValue,
        end_Date: props.BeginEndDate?.EndDateValue,
        error: "",
      });
    } else {
      for (let i = 0; i < props?.Quantity; i++) {
        ListData.push({
          id: i,
          begin_Date: props.BeginEndDate?.StartDateValue,
          end_Date: props.BeginEndDate.EndDateValue.split("T")[0],
          error: "",
        });
      }
    }
    setInputValues(ListData);
  }, [
    props?.Quantity,
    props.BeginEndDate?.StartDateValue,
    props.BeginEndDate.EndDateValue,
    props?.BeginEndDate?.MultipleDates,
  ]);

  useEffect(() => {
    props.setBeginEndDate({
      ...props.BeginEndDate,
      MultipleDatesData: inputValues,
      DepartureDate: "",
    });
  }, [inputValues]);

  const handleInputChange = (index, e) => {
    const newInputValues = [...inputValues];
    newInputValues[index][e.target.name] = e.target.value;

    if (props?.bundleData?.maximumPeriod != null && e.target.value != "") {
      let error_message = "";
      let begin_date = new Date(newInputValues[index]["begin_Date"]);
      let end_date = new Date(e.target.value);
      let diffTime = Math.abs(end_date - begin_date);
      let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      days = days + 1;
      let month = days / 30;
      let minDaysOrMonth = props?.bundleData?.minimumPeriod.split("|");
      let maxDaysOrMonth = props?.bundleData?.maximumPeriod.split("|");

      minDaysOrMonth[0] = parseInt(minDaysOrMonth[0]);
      maxDaysOrMonth[0] = parseInt(maxDaysOrMonth[0]);

      if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "d") {
        if (days < minDaysOrMonth[0]) {
          error_message =
            "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        } else if (days > maxDaysOrMonth[0]) {
          error_message =
            "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
        }
      } else if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "m") {
        if (days < minDaysOrMonth[0]) {
          error_message =
            "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        } else if (month > maxDaysOrMonth[0]) {
          error_message =
            "The maximum rental period for this group is " +
            maxDaysOrMonth[0] +
            " month.";
        }
      } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "m") {
        if (month < minDaysOrMonth[0]) {
          error_message =
            "The minimum rental period is " + maxDaysOrMonth[0] + " month.";
        } else if (month > maxDaysOrMonth[0]) {
          error_message =
            "The maximum rental period is " + maxDaysOrMonth[0] + " month.";
        }
      } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "d") {
        if (month < minDaysOrMonth[0]) {
          error_message =
            "The minimum rental period is " + minDaysOrMonth[0] + " month.";
        } else if (days > maxDaysOrMonth[0]) {
          error_message =
            "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
        }
      } else {
        if (days < minDaysOrMonth[0]) {
          error_message =
            "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        }
      }
      newInputValues[index]["error"] = error_message;
    }
    setInputValues(newInputValues);
  };
  return (
    <React.Fragment>
      {inputValues?.map((data) => (
        <div key={data?.id}>
          {props.BeginEndDate.isStartDateVisible && (
            <>
              <div className="col-12 my-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Day to Begin Service
                        {props?.Quantity > 1 &&
                          !props?.BeginEndDate?.MultipleDates && (
                            <> for order #{data.id + 1}</>
                          )}
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <input
                        className="fromCalender w-100"
                        // name="startDate"
                        name="begin_Date"
                        type="date"
                        id="fromCalender"
                        value={data?.begin_Date}
                        onChange={(e) => handleInputChange(data?.id, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {props.BeginEndDate?.isEndDateVisible && (
            <>
              <div className="col-12 my-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Day to End Service
                        {props?.Quantity > 1 &&
                          !props?.BeginEndDate?.MultipleDates && (
                            <> for order #{data.id + 1}</>
                          )}
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <input
                        className="fromCalender w-100"
                        name="end_Date"
                        type="date"
                        id="fromCalender"
                        value={data?.end_Date}
                        onChange={(e) => handleInputChange(data?.id, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-md-4 col-4 align-self-center"></div>
            <div className="col-md-8 col-8">
              {data?.error && <div style={{ color: "red" }}>{data?.error}</div>}
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiGetCall, ApiPostCall } from "../Functions/connector";
import { ValidateData, FillData } from "./PlaceOrderFun";
import $ from "jquery";
import LPPopup from "./LPPopup";
import POPopup from "./POPopup";
import { GetProducts } from "../Functions/CommonApiCall";
import { InputNumberGap, cc_format, getNineYear } from "../Helper/HelperFun";

export default function PlaceOrder() {
  const Token = window.localStorage.getItem("Token");
  const { subLinkId, shipId } = useParams();
  const [SubLinkData, setSubLinkData] = useState([]);
  const [show, setShow] = useState(false);
  const [ShipData, setShipData] = useState([]);
  const [BundleSubLinkData, setBundleSubLinkData] = useState([]);
  const [BundleData, setBundleData] = useState([]);
  const [InsuranceData, setInsuranceData] = useState({
    Visible: false,
    InnerText: "",
    InnerHtml: "",
    Checkbox: false,
    RadioBtn: "",
  });
  const [TermService, setTermService] = useState("");
  const [ShowSLNQ, setShowSLNQ] = useState(false);
  const [StartDate, SetStartDate] = useState({
    Value: "",
    Visible: true,
  });
  const [EndDate, SetEndDate] = useState({
    Value: "",
    Visible: true,
  });
  const [CountryData, SetCountryData] = useState({
    StateList: [],
    ShowState: false,
    ShowZipCode: false,
  });
  const [ErrorMsg, SetErrorMsg] = useState({
    err_visible: false,
    message: "",
  });
  const [Year, SetYear] = useState([]);
  const [successData, setSuccessData] = useState({ loader: false, result: {} });
  const [card, setCard] = useState("");

  useLayoutEffect(() => {
    SetYear(getNineYear());
    var urlencoded = new URLSearchParams();
    urlencoded.append("SubLinkId", subLinkId);
    urlencoded.append("ShipId", shipId);
    urlencoded.append("Token", Token);
    ApiPostCall(
      "/RouterParserLocationPortal/GetBundlesOrSubLinkList",
      urlencoded
    ).then((result) => {
      try {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          if (responseRs?.Status != "Error") {
            setSubLinkData(JSON.parse(responseRs.Data?.BundleSubLinkData)[0]);
            setShipData(JSON.parse(responseRs.Data?.ShipData)[0]);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        //   setLoader(false);
      }
    });
  }, []);

  const SelectBundle = (e) => {
    if (e != "") {
      GetProducts(subLinkId, e).then((result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = result;
            setBundleData(responseRs[0]?.bundles[0]);
            setBundleSubLinkData(responseRs);
            insuranceFun(responseRs[0]);
            termService(responseRs[0]?.minimumPeriod);
            setDates(responseRs[0]);
            // console.log(responseRs);
          }
        } catch (e) {
          console.log(e);
        } finally {
          //   setLoader(false);
        }
      });
    }
  };

  const setDates = (data) => {
    var {
      dateProgramStart,
      dateLeaveIL,
      optLockProgramDates,
      optLeaveILrequest,
    } = data;

    if (dateProgramStart != null) {
      var dLockProgramStartDate = new Date(dateProgramStart);
      if (dLockProgramStartDate < new Date()) {
        dateProgramStart = new Date();
      }
      SetStartDate({ ...StartDate, Value: dateProgramStart });
      if (optLockProgramDates) SetStartDate({ ...StartDate, Visible: false });
    }

    if (dateLeaveIL != null) {
      var dLockProgramEndDate = new Date(dateLeaveIL);
      if (dLockProgramEndDate < new Date()) {
        dateLeaveIL = null;
      } else {
        SetEndDate({ ...EndDate, Value: dateLeaveIL });
      }
      if (optLockProgramDates) SetEndDate({ ...EndDate, Visible: false });
    }
    if (!optLeaveILrequest) SetEndDate({ ...EndDate, Visible: false });
  };
  const insuranceFun = (data) => {
    const { insurancePrice, insurance, insurancePriceText, insuranceDesc } =
      data;

    var sPrice;
    var sText = "";
    if (insurancePrice == null) {
      sPrice = "";
      sText = "";
    } else if (insurancePrice > 1) {
      sPrice = "$" + insurancePrice;
      sText = sPrice + insurancePriceText + " " + insuranceDesc;
    } else {
      sPrice = insurancePrice * 100 + "¢ ";
      sText = sPrice + insurancePriceText + " " + insuranceDesc;
    }
    var insuranceObj = new Object();
    insuranceObj.Visible = true;
    insuranceObj.InnerText = "Insurance / ביטוח: ";

    switch (insurance) {
      case "not_relevant":
        insuranceObj.Visible = false;
        break;

      case "included":
        insuranceObj.Checkbox = true;
        sText = "Included/כלול! " + sText;
        insuranceObj.InnerHtml = sText;
        break;

      case "required":
        insuranceObj.Checkbox = true;
        sText = "Required/חובה! " + sText;
        insuranceObj.InnerHtml = sText;
        break;

      case "optional":
        insuranceObj.Checkbox = false;
        insuranceObj.InnerHtml = sText;
        insuranceObj.InnerText = "Do you want insurance? / ?האם להוסיף ביטוח:";
        break;
    }

    setInsuranceData({
      Visible: insuranceObj?.Visible,
      InnerText: insuranceObj?.InnerText,
      InnerHtml: insuranceObj?.InnerHtml,
      Checkbox: insuranceObj?.Checkbox,
    });
  };
  const termService = (min) => {
    if (min != null) {
      var sTxtPeriod;
      var min = min?.split("|");
      if (min[1] == "d") {
        sTxtPeriod = "Minimum Length of Contract: " + min[0] + " days.";
      } else if (min[1] == "m") {
        sTxtPeriod = "Minimum Length of Contract: " + min[0] + " months.";
      }
      setTermService(sTxtPeriod);
    }
  };
  const ChangeCountry = (e) => {
    let CountryValue = e;
    if (CountryValue == 1) {
      SetCountryData({
        ...CountryData,
        StateList: BundleSubLinkData[0]?.UsaStates,
        ShowState: true,
        ShowZipCode: true,
      });
    } else if (CountryValue == 7) {
      SetCountryData({
        ...CountryData,
        StateList: BundleSubLinkData[0]?.CanadaStates,
        ShowState: true,
        ShowZipCode: true,
      });
    } else if (CountryValue == 4) {
      SetCountryData({
        ...CountryData,
        StateList: [],
        ShowState: false,
        ShowZipCode: false,
      });
    } else {
      SetCountryData({
        ...CountryData,
        StateList: [],
        ShowState: false,
        ShowZipCode: true,
      });
    }
  };

  const SubmitData = (e, bundleSubLinkData) => {
    e.preventDefault();

    // console.log(e.target.startDate.value);
    Validation(e, bundleSubLinkData).then((result) => {
      if (!result) {
        let data = FillData(e, bundleSubLinkData, ShipData);
        if (data != null && data.length > 0) {
          console.log(data);
          setShow(true);
          data[0].bitUSA_SIM_Order= true;
          data[0].USA_SIM_Order= true;

          var raw = JSON.stringify(data);
          console.log(raw);

          setSuccessData({ ...successData, loader: true });
          $.post(
            "https://talknsave.net/wp-content/themes/betheme-child/LocationPortalOrder.php",
            {
              SaveApiData: raw,
            },
            function (msg) {
              try {
                let result = JSON.parse(msg);
                setSuccessData({
                  ...successData,
                  loader: false,
                  result: result,
                });
                console.log(msg);
              } catch (error) {
                console.log(error);
              }
              // finally {
              //   setSuccessData({ ...successData, loader: false });
              // }
            }
          );
          // ApiPostCall(
          //   "/LocationPortalOrder/LocationPortalOrderPlaceAndPay",
          //   raw,
          //   "application/json"
          // ).then((results) => {
          //   try {
          //     let result = JSON.parse(results);
          //     setSuccessData({
          //       ...successData,
          //       loader: false,
          //       result: result,
          //     });
          //     console.log(result);
          //   } catch (error) {
          //     console.log(error);
          //   }
          // });
        }
      }
    });
  };

  const Validation = async (data, bundleSubLinkData) => {
    let { isDateVisible, err_date_msg } = await ValidateData(
      data,
      bundleSubLinkData
    );
    SetErrorMsg({
      err_visible: isDateVisible,
      message: err_date_msg,
    });
    return isDateVisible;
    // SetErrorMsg((prevState) => ({
    //   ...prevState,
    //   Err_Dates: {
    //     ...prevState.Err_Dates,
    //     err_visible: isDateVisible,
    //     message: err_date_msg,
    //   },
    // }));
  };

  return (
    <React.Fragment>
      <div className="pageMargin">
        <form onSubmit={(e) => SubmitData(e, BundleSubLinkData)}>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="col-12 ro-font1 c142454">
                Plan Details / פרטי התוכנית
              </div>
              <div className="col-12 my-3">
                <div className="form-group">
                  <label>
                    Bundle / חבילה:<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select select mb-0"
                    name="BundleData"
                    id=""
                    onChange={(e) => SelectBundle(e.target.value)}
                  >
                    <option value="-1">Select a plan</option>
                    {SubLinkData?.map((data, i) => {
                      return (
                        <>
                          <option value={data?.Counter}>
                            {data?.BundleName.replace("<br />", " - ").replace(
                              "<br>",
                              " "
                            )}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              {BundleSubLinkData.length > 0 && (
                <>
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <label>
                        Equipment / ציוד:<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select select mb-0"
                        name="equipmentData"
                        id=""
                      >
                        <option value="-1">Please select</option>
                        {BundleData?.equipments?.map((data, i) => {
                          return (
                            <>
                              {data?.EquipmentCode != 9999 &&
                                data?.EquipmentCode != 3290 && (
                                  <option
                                    value={data?.EquipmentId}
                                    data-name={data?.name}
                                    data-cost={data?.ECost}
                                    data-code={data?.EquipmentCode}
                                    data-note={data?.Notes}
                                    data-issns={data?.IsSns}
                                    data-kosher={data?.kosher}
                                    data-issim={data?.IsSim}
                                    data-issmartphone={data?.IsSmartPhone}
                                  >
                                    {data?.name}
                                  </option>
                                )}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {BundleSubLinkData[0]?.optAllowMultipleRentals && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <label>
                            Equipment Quantity / כמות הציוד:
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select select mb-0"
                            name="EquipmentQuantity"
                            id=""
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}

                  {InsuranceData.Visible && (
                    <>
                      <div className="col-12 my-3">
                        <div className="form-group">
                          <label>{InsuranceData.InnerText}</label>

                          {InsuranceData.Checkbox ? (
                            <>
                              <div className="row my-2">
                                <div className="col-12">
                                  <div className="form-check">
                                    <label
                                      className="form-check-label ro-font2 c142454"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {InsuranceData.InnerHtml}
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      name=""
                                      checked
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row my-2">
                                <div className="col-12 c142454 ro-font2">
                                  {InsuranceData.InnerHtml}
                                </div>
                              </div>
                              <div
                                className="d-flex"
                                onChange={(e) =>
                                  setInsuranceData({
                                    ...InsuranceData,
                                    RadioBtn: e.target.id,
                                  })
                                }
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="InsuranceRadio"
                                    id="InsuranceRadioYes"
                                    value={InsuranceData.RadioBtn}
                                    defaultChecked={
                                      InsuranceData.RadioBtn ===
                                        "InsuranceRadioYes" || ""
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="ms-2 form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="InsuranceRadio"
                                    id="InsuranceRadioNo"
                                    value={InsuranceData.RadioBtn}
                                    defaultChecked={
                                      InsuranceData.RadioBtn ===
                                        "InsuranceRadioNo" || ""
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              {InsuranceData.RadioBtn == "InsuranceRadioNo" && (
                                <>
                                  <div className="row my-2">
                                    <div className="col-12">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          name="NoInsuranceConfirmationCB"
                                        />
                                        <label
                                          className="form-check-label ro-font2 text-danger"
                                          htmlFor="flexCheckDefault"
                                        >
                                          I Acknowledge the fact that I have
                                          declined insurance coverage.
                                          <br /> In the case of damage to the
                                          phone, it will be repaired at my own
                                          expense.
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {BundleData?.kntCountries?.length > 0 && (
                    <div className="col-12 my-3">
                      <div className="form-group">
                        <label>
                          Stay Local Number / מספר מקומי:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select select mb-0"
                          name="kntCountries"
                          id=""
                          onChange={(e) => {
                            setShowSLNQ(
                              e.target.value == "0" || e.target.value == "-1"
                                ? false
                                : true
                            );
                          }}
                        >
                          <option value="0">Please select</option>
                          {BundleData?.kntCountries?.map((data, i) => {
                            return (
                              <>
                                <option value={data?.KNTCode}>
                                  {data?.DirectDisplayName}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                  {BundleSubLinkData[0]?.optAllowMultipleRentals &&
                    ShowSLNQ && (
                      <>
                        <div className="col-12 my-3">
                          <div className="form-group">
                            <label>
                              Stay Local Number Quantity / כמות מספרים מקומיים:
                              *
                            </label>
                            <select
                              className="form-select select mb-0"
                              name="StayLocalNumberQuantity"
                              id=""
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
              {BundleSubLinkData[0]?.bundles[0]?.smss.length > 1 && (
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>SMS:</label>
                    <label>
                      Your plan includes local (Israel) SMS. Now you can prepay
                      international text messages and save even more! <br />
                      התוכנית שלך כוללת מסרונים מקומיים (בישראל). עתה הינך
                      יכול/ה לשלם מראש על מסרוני טקסט ולחסוך אפילו יותר!
                    </label>
                    <select
                      className="form-select select mb-0"
                      name="smss"
                      id=""
                    >
                      <option value="-1">Please select</option>
                      {BundleSubLinkData[0]?.bundles[0]?.smss.map((data, i) => {
                        return (
                          <>
                            <option
                              key={data?.SmsPackageCode}
                              value={data?.SmsPackageCode}
                              data-name={data?.SMSPackageName}
                            >
                              {data?.SMSPackageName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
            {BundleSubLinkData.length > 0 && (
              <div className="col-md-4 col-12">
                <div className="col-12 ro-font1 c142454">
                  User Details / פרטי משתמש
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Term of Services / תנאי שירות:
                      <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-12 c142454 ro-font3">
                        {TermService}
                      </div>
                    </div>
                  </div>
                </div>
                {StartDate.Visible && (
                  <>
                    <div className="col-12 my-3">
                      <div className="form-group">
                        <label>
                          Day to Begin Service / יום תחילת השירות:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="fromCalender"
                          name="startDate"
                          type="date"
                          id="fromCalender"
                          value={StartDate.Value.split("T")[0]}
                          onChange={(e) =>
                            SetStartDate({
                              ...StartDate,
                              Value: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {EndDate?.Visible && (
                  <>
                    <div className="col-12 my-3">
                      <div className="form-group">
                        <label>
                          Day to End Service / יום סיום השירות:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="fromCalender"
                          name="endDate"
                          type="date"
                          id="fromCalender"
                          value={EndDate.Value.split("T")[0]}
                          onChange={(e) =>
                            SetEndDate({ ...EndDate, Value: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      First Name / שם פרטי:
                      <span className="text-danger">*</span>
                    </label>
                    <input className="box w-100" name="firstName" type="text" />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Last Name / שם משפחה:
                      <span className="text-danger">*</span>
                    </label>
                    <input className="box w-100" name="lastName" type="text" />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Email / דוא"ל:<span className="text-danger">*</span>
                    </label>
                    <input className="box w-100" name="email" type="text" />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label
                      className="form-check-label ro-font2 c142454"
                      htmlFor="flexCheckDefault"
                    >
                      Please do not include international prefixes (001 for US
                      etc.)נא לא לכלול קידומות חו"ל (לדוגמא 001 עבור ארה"ב וכו')
                    </label>
                    <label>
                      Home Phone Number / מספר טלפון ביתי:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="phoneNumber"
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Street Address / כתובת:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="streetAddress"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      City / עיר:<span className="text-danger">*</span>
                    </label>
                    <input className="box w-100" name="city" type="text" />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Country / מדינה:<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select select mb-0"
                      name="CountryData"
                      id=""
                      onChange={(e) => ChangeCountry(e.target.value)}
                    >
                      <option value="-1">Please select</option>
                      {BundleSubLinkData[0]?.countries?.map((data, i) => {
                        return (
                          <>
                            <option
                              key={data?.CountryID}
                              value={data?.CountryID}
                              data-countryname={data?.CountryName}
                            >
                              {data?.CountryName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {CountryData?.ShowState && (
                  <>
                    <div className="col-12 my-3">
                      <div className="form-group">
                        <label>
                          State/Province / מחוז:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select select mb-0"
                          name="StateData"
                          id=""
                        >
                          <option value="-1">Please select</option>
                          {CountryData?.StateList.map((data, i) => {
                            return (
                              <>
                                <option
                                  key={data?.StateCode}
                                  value={data?.StateCode}
                                >
                                  {data?.StateName}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {CountryData?.ShowZipCode && (
                  <>
                    <div className="col-12 my-3">
                      <div className="form-group">
                        <label>ZIP/Postal Code / מיקוד:</label>
                        <input
                          className="box w-100"
                          name="ZipCode"
                          type="text"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {BundleSubLinkData.length > 0 && (
              <div className="col-md-4 col-12">
                <div className="col-12 ro-font1 c142454">
                  Payment Details / פרטי תשלום
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      First Name / שם פרטי:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="billingFirstName"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Last Name / שם משפחה:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="billingLastName"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Cardholder Email / דוא"ל:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="billingEmail"
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Card Number / מספר הכרטיס:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="box w-100"
                      name="billingCardNumber"
                      type="type"
                      value={InputNumberGap(card, 16)}
                      onChange={(e) => setCard(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Card Type / סוג הכרטיס:
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select select mb-0"
                      name="billingCardType"
                      id=""
                    >
                      <option value="0">Please select</option>
                      <option value="Amex">Amex</option>
                      <option value="Discover">Discover</option>
                      <option value="Mastercard">Mastercard</option>
                      <option value="Visa">Visa</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Expiration Date / תוקף:
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <select
                        className="form-select select mb-0"
                        name="billingExpireMonth"
                        id=""
                      >
                        <option value="0">Select Month</option>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                    <div className="col-6">
                      <select
                        className="form-select select mb-0"
                        name="billingExpireYear"
                        id=""
                      >
                        <option value="0">Select Year</option>
                        {Year.map((yr, i) => (
                          <>
                            <option key={i} value={yr.toString().substring(2)}>
                              {yr}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>
                      Coupon/Voucher Code(not required) / קוד קופון:
                    </label>
                    <input className="box w-100" name="coupon" type="text" />
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="form-group">
                    <label>Optional comments / הערות:</label>
                    <textarea className="box w-100" name="ccNote" />
                    <label className="form-check-label ro-font2 c142454">
                      If there are two or more phone numbers, please write them
                      as: 050709XXXX;050709XXXX.אם יש שני מספרי טלפון ומעלה, נא
                      לרשום אותם כ: 050709XXXX;050709XXXX <br />{" "}
                      {SubLinkData[0]?.ClientCode == 3
                        ? "SIM Number#:"
                        : "Talknsave Phone#:"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input className="box w-100" name="TNSPhone" type="text" />
                  </div>
                </div>
              </div>
            )}
          </div>
          {BundleSubLinkData.length > 0 && (
            <>
              {BundleSubLinkData[0]?.bundles[0]?.optionals.length > 0 && (
                <div
                  className="row mt-4 py-4"
                  style={{ borderTop: "1px solid #E2E2E2" }}
                >
                  <div className="col-12">
                    <div className="optionalContainer">
                      <div className="row py-2 mx-0">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4 ro-font2 ">
                              Optional / אופציונלי
                            </div>
                            <div className="col-2 ro-font2 ">Rate / תעריף</div>
                            <div className="col-2 ro-font2 ">
                              Insurance / ביטוח
                            </div>
                            <div className="col-2 ro-font2 ">
                              Quantity / כמות
                            </div>
                          </div>
                        </div>
                      </div>
                      {BundleSubLinkData[0]?.bundles[0]?.optionals.map(
                        (data, i) => {
                          return (
                            <>
                              <div className="row py-3 mx-0 phonecard">
                                <div className="col-12">
                                  <div className="row align-items-center">
                                    <div className="col-2 text-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id=""
                                        name="ifOptional"
                                        clientcode={data?.ClientCode}
                                        couponcode={data?.CouponCode}
                                        deposit={data?.Deposit}
                                        equipmentcode={data?.Equipment_code}
                                        insurance={data?.Insurance.toString()}
                                        optionalcode={data?.OptionalCode}
                                        optionalfeedesc={data?.Rate}
                                        optionalimg={data?.image}
                                        optionalname={data?.chckBxName}
                                        plancode={data?.Plan_code}
                                        requiredinsurance={data?.Insurance.toString()}
                                        requiredoperationsystem={data?.IsRequiredOperationSystem.toString()}
                                      />
                                    </div>
                                    <div className="col-4 ro-font3 ">
                                      {data?.chckBxName.replace("<br/>", "")}
                                    </div>
                                    <div className="col-2 ro-font3 ">
                                      {data?.Rate}
                                    </div>

                                    <div className="col-2 ro-font3 ">
                                      {data?.optRequiredInsurance && (
                                        <input
                                          className="form-check-input text-center"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          name=""
                                        />
                                      )}
                                    </div>
                                    <div className="col-2 ro-font3 pe-md-5">
                                      <input
                                        className="box w-100 h-100"
                                        name="OptionalQty"
                                        type="number"
                                        defaultValue={1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="row mb-4 py-4"
                style={{ borderTop: "1px solid #E2E2E2" }}
              >
                <div className="col-md-12">
                  <label>Authorization / אישור:</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      name="authorizationCheckbox"
                    />
                    <label
                      className="form-check-label ro-font2 text-danger"
                      htmlFor="flexCheckDefault"
                    >
                      I hereby agree to the Terms and Conditions of the service.
                      I understand rates are only guaranteed when I follow the
                      dialing directions packaged with my rentalהנני מסכים/ה
                      לתנאי השירות. הנני מבין שהתעריפים מובטחים אך ורק אם אעקוב
                      אחרי הנחיות החיוג הארוזות עם המושכר
                    </label>
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-md-4">
                  {ErrorMsg?.err_visible && (
                    <>
                      <span className="text-danger">{ErrorMsg?.message}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="col-md-4">
                  <button
                    type="submit"
                    className="btn ro-button btn-d-flex py-2 w-100 btn0"
                  >
                    Place Your Order! / ביצוע הזמנה
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      <LPPopup show={show}>
        <POPopup successData={successData} setShow={setShow} />
      </LPPopup>
    </React.Fragment>
  );
}

import React from "react";

export default function orderStatusContainer(props) {
  const { tblOnlineData, OsData, callData } = props;
  console.log("OsData");
  console.log(OsData);
  return (
    <>
      <div className="col-lg-6 col-md-6 col-12  OS_Conatiner">
        <div className="vertical"></div>
        <div className="row ODFont4 c142454">
          Order # {tblOnlineData.OnlineOrderCode}
        </div>
        <div className="row my-2">
          <div className="col-lg-6 col-md-6 col-12 p-0">
            <div className="OD_Container p-1 m-1">
              <div className="row ODFont5 p-3 c142454">
                {OsData?.EquipmentModel}
              </div>
              {tblOnlineData?.Insurance &&
                OsData?.EquipmentModel == "Mobile Hotspot" && (
                  <>
                    <div className="row ODFont5 px-3 c142454">
                      Insurance : Yes
                    </div>
                  </>
                )}

              <div className="row ODFont2 p-3 c142454">Equipment</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 p-0">
            <div className="OD_Container p-1 m-1">
              <div className="row ODFont5 p-3 c142454">
                {callData?.CallPackageName}
              </div>
              <div className="row ODFont2 p-3 c142454">Voice Plan</div>
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="row ODFont2 c4B758C ">Text Plan</div>
          <div className="row ODFont1 c142454 ">{OsData.IsKosher==true? "No Text" : callData?.SMSPackageName}</div>
        </div>
        <div className="row my-2">
          <div className="row ODFont2 c4B758C">Data Plan</div>
          <div className="row ODFont1 c142454 ">
            {OsData.IsKosher==true? "No Data" : callData?.ExtendedDataPackageName}
          </div>
        </div>
        {/* <div className="row my-2">
          <div className="row ODFont2 c4B758C">Virtual Number</div>
          <div className="row ODFont1 c142454 ">Test</div>
        </div> */}
      </div>
    </>
  );
}

import React from "react";
import "./payment.css";
import DeleteCard from "./deleteCard";
import AddNewCard from "./addNewCard";
import Cards from "./Card";
import VisaCard from "./visaCard";
import PopupModal from "../Common/Popup";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      setLoader: true,
    };
  }

  componentDidMount() {
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/Rentals ", urlencoded).then((result) => {
      // console.log("got it");
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        const datas = JSON.parse(result);
        const datas2 = JSON.parse(datas);
        var d = datas2;
        var element = [];
        for (let i = 0; i < d.length; i++) {
          element = d[i];
        }
        this.setState({ data: element });
        this.setState({ setLoader: false });
      }
    });
  }

  render() {
    var cnum = [];
    var Card = [];
    var datas = this.state.data;
    if (this.state.data) {
      for (let j = 0; j < this.state.data.length; j++) {
        if (this.state.data[j]) {
          // if ($.inArray(datas[j].CCNum, cnum) < 0) {
          Card.push(
            <>
              <Cards
                CName={datas[j].ClientName}
                Cnum={datas[j].CCNum}
                title={datas[j].CCTitle}
                eModel={datas[j].EquipmentModel}
                pnumbers={datas[j].PhoneNumber}
                RCODE={datas[j].RentalCode}
              />
            </>
          );
          // cnum.push(datas[j].CCNum);
          // }
        }
      }
    }
    return (
      <>
        {this.state.setLoader ? (
          <>
            <span className="d-none isGotData">0</span>
            <div className="loader_positon text-center">
              <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
              <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
                Loading...
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="topContainer">
                <div className="font1">Payments Methods</div>
              </div>
            </div>
          </div> */}
            <div className="paymentMargein">
              <div className="row pt-4 px-2 placeContent">
                {Card}

                <div className="col-lg-4 col-md-4 m-1 mt-3 col-12 textCenter">
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className=""
                    class="AddContainer"
                    btnName={
                      <>
                        <div className="font3">+</div>
                        <div className="font4">Add New Card</div>
                      </>
                    }
                    size="addNewCardSize deleteCardDismissBtn"
                  >
                    <AddNewCard title="Add New Card" />
                  </PopupModal>
                  {/* <div className="AddCardStyle">
                <div className="font3">+</div>
                <div className="font4">Add New Card</div>
              </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default PaymentMethods;
